
import { lazy } from "react";
import { PATH } from "routes/PATH";

const Login = lazy(() => import("pages/auth/Login"));
const VerifyForgotPassword = lazy(() =>
  import("pages/auth/VerifyForgotPassword")
);
const AwaitSignUp = lazy(() => import("pages/auth/AwaitSignUp"));
const Contact = lazy(() => import("pages/Contact"));

const VerifyEmail = lazy(() => import("pages/auth/VerifyEmail"));
const Register = lazy(() => import("pages/auth/Register"));
const ForgotPass = lazy(() => import("pages/auth/ForgotPass"));
const Policy = lazy(() => import("pages/Policy"));
const ResendMail = lazy(() => import("pages/auth/ResendMail"));

export const auth = [
  {
    name: "Login",
    path: PATH.AUTH.LOGIN,
    isPrivate: false,
    component: <Login />,
  },
  {
    name: "Register",
    path: PATH.AUTH.REGISTER_SLUG,
    isPrivate: false,
    component: <Register />,
    // component: <ComingSoon />,
  },
  {
    name: "Register",
    path: PATH.AUTH.REGISTER,
    isPrivate: false,
    component: <Register />,
    // component: <ComingSoon />,
  },
  // {
  //   name: "Register",
  //   path: PATH.AUTH.REGISTERID,
  //   isPrivate: false,
  //   component: <Register />,
  // },
  {
    name: "Verify",
    path: PATH.AUTH.VERIFY,
    isPrivate: false,
    component: <VerifyEmail />,
  },
  {
    name: "Await",
    path: PATH.AUTH.AWAIT,
    isPrivate: false,
    component: <AwaitSignUp />,
  },
  {
    name: "Forgot Password",
    path: PATH.AUTH.FORGOT,
    isPrivate: false,
    component: <ForgotPass />,
  },
  {
    name: "Resend Mail",
    path: PATH.AUTH.RESEND_MAIL,
    isPrivate: false,
    component: <ResendMail />,
  },
  {
    name: "Verify Forgot Password",
    path: PATH.AUTH.VERIFYFORGOT,
    isPrivate: false,
    component: <VerifyForgotPassword />,
  },
  {
    name: "Policy",
    path: PATH.AUTH.POLICY,
    isPrivate: false,
    component: <Policy />,
  },

  {
    name: "Contact",
    path: PATH.AUTH.CONTACT,
    isPrivate: false,
    component: <Contact />,
  },
];
