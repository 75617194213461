/* eslint-disable react/jsx-pascal-case */
import { Links, listHeader, ListMegaMenu, ListMegaMenuChildren } from "./Data";
import {
  StyledHeader,
  Menu,
  Logo,
  Span,
  Nav,
  StyledButton,
  MenuMoblie,
  IconMenu,
  NavMoblie,
  NavMoblieItem,
  StyledButtonMoblie,
  Item,
  OverlayScreen,
  IconClose,
  MegaMenu,
  NavMoblieItemChildren,
  MenuHeader,
} from "./Header.styled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Image from "assets/svg/vfc.svg";
import { set } from "nprogress";
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { GetUser } from "utits/auth";

function Header() {
  const wrapperRef = useRef();
  const [menu, setMenu] = useState(false);
  const [transparent, setTransparent] = useState(false);
  const navigate = useNavigate();
  const user = GetUser();
  const location = useLocation();

  const handleMenuMoblie = () => {
    setMenu(!menu);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleUserScrolling);
    return () => {
      window.removeEventListener("scroll", handleUserScrolling);
    };
  }, [transparent]);

  const handleUserScrolling = () => {
    if (wrapperRef && wrapperRef.current) {
      const BOUNDARY_OF_TRANSPARANT_BG = wrapperRef.current.clientHeight;
      const scrollPosition = document.documentElement.scrollTop;
      if (scrollPosition > BOUNDARY_OF_TRANSPARANT_BG) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    }
  };

  let [megaMenu, setMegaMenu] = useState(false);
  let handleOnMouseEnter = (e) => {
    if (e === "NFT") setMegaMenu(e);
  };
  let handleOnMouseLeave = (e) => {
    setMegaMenu("");
  };

  const [active, setActive] = useState(false);
  // let [megaMenuChildren, setMegaMenuChildren] = useState("");
  // let handleOnMouseEnterChildren = (e) => {
  //   if (e === "ECOSYSTEM") setMegaMenuChildren(e);
  // };
  // let handleOnMouseLeaveChildren = (e) => {
  //   setMegaMenuChildren("");
  // };
  console.log(active);
  return (
    <>
      {menu && <OverlayScreen onClick={() => setMenu(!menu)} />}
      <StyledHeader
        bg={menu ? menu : transparent}
        ref={wrapperRef}
        megaMenu={megaMenu}
      >
        <Container style={{ height: "100%" }}>
          <Nav>
            <Link to={"/"}>
              {" "}
              <Logo src={Image} />
            </Link>
            <Menu>
              {" "}
              {listHeader?.map((e, i) => (
                <MenuHeader.main
                  active={
                    e?.title == "NFT"
                      ? location.pathname == "/maps" ||
                        location.pathname == "/land" ||
                        location.pathname == "/picture"
                        ? true
                        : false
                      : false
                  }
                  onMouseEnter={() => handleOnMouseEnter(e.title)}
                  onMouseLeave={() => handleOnMouseLeave(e.title)}
                >
                  <div>
                    <div>
                     

                     {location.pathname != "/" ? 
                     <>
                      {e?.title != "NFT" ? (
                        <a href={`/`}>{e.title}</a>
                      ) : (
                        <div>{e.title}</div>
                      )}{" "}
                      </>
                      
                      :  <>
                      {e?.title != "NFT" ? (
                        <a href={`#${e.title}`}>{e.title}</a>
                      ) : (
                        <div>{e.title}</div>
                      )}{" "}
                      </>
                    }
                    </div>
                    {megaMenu == "NFT" && (
                      <MenuHeader.children>
                        {e?.children &&
                          e?.children?.map((e, i) => (
                            <div onClick={() => setActive(true)}>
                              <NavLink to={e?.link}>{e?.name}</NavLink>
                            </div>
                          ))}
                      </MenuHeader.children>
                    )}
                  </div>
                </MenuHeader.main>
              ))}
              <MenuHeader.main
                active={location?.pathname == "/metashop" ? true : false}
              >
                <div>
                  <div>
                    <NavLink to={`/metashop`}>Meta Shop</NavLink>{" "}
                  </div>
                </div>
              </MenuHeader.main>
              <MenuHeader.main
               
              >
                <div>
                  <div>
                    <a
                      href=" https://tobenft.net/promotion.pdf"
                      target="_blank"
                    >
                      Promotion
                    </a>{" "}
                  </div>
                </div>
              </MenuHeader.main>
              {/* {listHeader?.map((e, i) => (
                <Span
                  key={i}
                  bg={menu ? menu : transparent}
                  active={location.pathname == e.link}
                  onMouseEnter={() => handleOnMouseEnter(e.title)}
                  onMouseLeave={() => handleOnMouseLeave(e.title)}
                >
                  {e?.link ? (
                    <NavLink to={e?.link}> {e.title} </NavLink>
                  ) : (
                    e?.title
                  )}{" "}
                </Span>
              ))} */}
            </Menu>
            {!user ? (
              <div>
                <StyledButton
                  onClick={() => navigate("/auth/register")}
                  style={{ marginRight: "10px" }}
                >
                  Register
                </StyledButton>
                <StyledButton
                  onClick={() => navigate("/auth/login")}
                  // style={{
                  //   background:
                  //     "linear-gradient(180deg, rgba(234, 2, 116, 0.3) 0%, rgba(123, 13, 106, 0.3) 100%)",
                  //   border: "1px solid rgba(255, 255, 255, 0.3)",
                  // }}
                >
                  Login
                </StyledButton>
              </div>
            ) : (
              <StyledButton
                onClick={() => navigate("/account")}
                style={{ marginRight: "10px" }}
              >
                Dashboard
              </StyledButton>
            )}

            <MenuMoblie onClick={handleMenuMoblie}>
              {!menu ? (
                <IconMenu style={{ color: "white" }} />
              ) : (
                <IconClose style={{ color: "white" }} />
              )}
            </MenuMoblie>
          </Nav>

          <NavMoblie menu={menu && "active"}>
            <NavMoblieItem>
              {listHeader.map((e, i) => (
                <Item
                  key={i}
                  menu={menu && "active"}
                  ac={
                    e?.title == "NFT"
                      ? location.pathname == "/maps" ||
                        location.pathname == "/land" ||
                        location.pathname == "/picture"
                        ? true
                        : false
                      : false
                  }
                  style={{
                    borderBottom: `${
                      e?.title == "NFT" ? "none" : "1px solid #a39d9d"
                    } `,
                  }}
                >
                  {e.link ? (
                    <NavLink to={e?.link} onClick={() => setMenu(!menu)}>
                      {" "}
                      {e?.title}
                      {/* <ArrowRightIcon /> {" "} */}
                    </NavLink>
                  ) : (
                    e?.title
                  )}
                  {e?.children && (
                    <NavMoblieItemChildren>
                      {ListMegaMenuChildren.map((e, i) => {
                        return (
                          <div>
                            <NavLink to={e.link} onClick={() => setMenu(!menu)}>{e.title}</NavLink>
                          </div>
                        );
                      })}
                    </NavMoblieItemChildren>
                  )}
                </Item>
              ))}
              <Item menu={menu && "active"}>
                <NavLink to={`/metashop`} onClick={() => setMenu(!menu)}>
                  {" "}
                  Meta Shop
                  {/* <ArrowRightIcon /> {" "} */}
                </NavLink>
              </Item>
              <Item>
                <a href=" https://tobenft.net/promotion.pdf" target="_blank" onClick={() => setMenu(!menu)}>
                  Promotion
                </a>{" "}
              </Item>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {Links?.map((e, i) => (
                  <div key={i}>
                    <a href={e.link} target="_blank">
                      <img src={e.image} />
                    </a>
                  </div>
                ))}
              </div>
              {user ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <StyledButtonMoblie onClick={() => navigate("/account")}>
                    Dashboard
                  </StyledButtonMoblie>
                </div>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <StyledButtonMoblie
                    onClick={() => navigate("/auth/register")}
                    style={{ marginRight: "10px" }}
                  >
                    Register
                  </StyledButtonMoblie>
                  <StyledButtonMoblie
                    onClick={() => navigate("/auth/login")}
                    style={{
                      background: "#02FFAC",
                      border: "1px solid rgba(255, 255, 255, 0.3)",
                      marginTop: "10px",
                    }}
                  >
                    Login
                  </StyledButtonMoblie>
                </div>
              )}
            </NavMoblieItem>
          </NavMoblie>
        </Container>
      </StyledHeader>
      {/* {megaMenu == "NFT" && (
        <MegaMenu.main
          onMouseEnter={() => handleOnMouseEnter("NFT")}
          onMouseLeave={() => handleOnMouseLeave("")}
        >
          <Container>
            <MegaMenu.wrapper>
              <MegaMenu.context>
                {ListMegaMenu.map((e, i) => {
                  return (
                    <div
                      key={i}
                      onMouseEnter={() => handleOnMouseEnterChildren(e.title)}
                      onMouseLeave={() => handleOnMouseLeaveChildren(e.title)}
                      style={{ position: "relative" }}
                    >
                      <NavLink
                        target={e?.link === "litepaper.pdf" ? "_blank" : ""}
                        to={e?.link}
                      >
                        {e.title}
                      </NavLink>
                      {megaMenuChildren === "ECOSYSTEM" &&
                        megaMenuChildren === e.title && (
                          <MegaMenu.children
                            onMouseEnter={() =>
                              handleOnMouseEnterChildren("ECOSYSTEM")
                            }
                            onMouseLeave={() =>
                              handleOnMouseLeaveChildren(e.title)
                            }
                          >
                            {ListMegaMenuChildren.map((e, i) => {
                              return (
                                <div key={i}>
                                  <NavLink to={e.link}>
                                    <ArrowRightIcon /> {e.title}
                                  </NavLink>
                                </div>
                              );
                            })}
                          </MegaMenu.children>
                        )}
                    </div>
                  );
                })}
              </MegaMenu.context>
            </MegaMenu.wrapper>
          </Container>
        </MegaMenu.main>
      )} */}
    </>
  );
}

export default Header;
