import Profit from "./Profit";
import ProfitOnProfit from "./ProfitOnProfit";
import { useSelector } from "react-redux";

const History = () => {
  const { menuDrawer } = useSelector((state) => state.menu);
  return (
    <>
      {menuDrawer === "/history" && <Profit />}
      {menuDrawer === "profit-on-profit" && <ProfitOnProfit />}
    </>
  );
};
export default History;
