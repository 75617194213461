import Filter from "./Filter";
import NFTLocation from "./NFTLocation";
import { Title, Pagi, Wrapper } from "./styles";
import Footer from "pages/HomeMain/footer";
import Header from "pages/HomeMain/header";
import { useSelector } from "react-redux";
import NFTLocations from "./NFTLocations";
import BannerCountDown from "./BannerCountDown";

const Marketplace = () => {
  return (
    <>
      <Header />
    <section style={{background:'#00000'}}>

      <div className="container">
        <Wrapper.Main data-aos="fade-up">
          <Wrapper.Text>META SHOP</Wrapper.Text>
        </Wrapper.Main>
        <Filter />
        <NFTLocations />
      </div>
      {/* <BannerCountDown /> */}
      </section>

      <Footer />
      </>
  );
};

export default Marketplace;
