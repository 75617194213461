import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Wrapper = {
  section: styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
  `,
  main: styled.div`
    background: rgba(255, 255, 255, 0.2);
    max-width: 600px;
    margin: auto;
    border-radius: 20px;
  `,
  content: styled.div`
    padding: 30px 20px 30px 20px;
    ${themes.mixin.flexDirectionNotCenter()};
    gap: 20px;
    >h3{
        color: ${themes.colors.white};
        text-align:center;
    }
    >button{
      width: 277px;
      height: 45px;
      background: #02FFAC;
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 74px;
      color:#007E95;
      text-transform: uppercase;
      font-weight:bold;
      margin:auto;
      >a {
          font-weight:700;
      }
  `,
  title: styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 10px 15px;
    background: ${themes.colors.primary};
    border-radius: 20px 20px 0px 0px;
    color: ${themes.colors.white};

    > h2 {
      color: white;
    }
  `,
};
