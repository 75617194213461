import { Voucher } from "../styles";
import { Section, Content, ContainerStyled, Items } from "./styles";
import bg2 from "assets/img/background2.png";
import bg from "assets/img/background.png";
import loadingG from "assets/img/loadingg.gif";
import Arrow from "assets/svg/virtual/arrow.svg";
import Desert from "assets/svg/virtual/desert.svg";
import Snow from "assets/svg/virtual/snow ice.svg";
import Valley from "assets/svg/virtual/valley.svg";
import Water from "assets/svg/virtual/water.svg";
import EmptyData from "components/EmptyData";
import SVG from "components/SVG";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleGetMyVirtualMachine } from "redux/slices/nft";
import { fixedNumber, formatMoney } from "components/FormatNumber";

const VirtualMachine = () => {
  const dispatch = useDispatch();
  const { listMyVirtualMachine, loadding } = useSelector((state) => state.nft);
  useEffect(() => {
    dispatch(handleGetMyVirtualMachine());
  }, []);
  return (
    <Section>
      <ContainerStyled>
        {loadding ? (
          <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <img src={loadingG} alt="" style={{ width: "15%" }} />
          </div>
        ) : (
          <>
            <Content>
              {listMyVirtualMachine?.total === 0 && <EmptyData />}
              {listMyVirtualMachine?.data?.map((e, i) => (
                <Voucher.main>
                  <Voucher.item>
                    <div> ${formatMoney(String(fixedNumber(e.price_package, 2)))}</div>
                  </Voucher.item>

                  <Voucher.item2>
                    <div>
                      <Voucher.Top>
                     
                        <Voucher.Title>GIFT VOUCHER</Voucher.Title>
                  <div style={{color:'rgb(42 154 117)',fontFamily: "Jockey One", fontSize:"28px"}}>{e?.name_location}</div>

                        <Voucher.Text>
                          <div>
                            <span>
                            {e?.date_start.substring(0, 10)}
                            </span>
                          </div>
                          <div>
                            <span>
                            {e?.date_end.substring(0, 10)}
                            </span>
                          </div>
                        </Voucher.Text>

                      </Voucher.Top>

                      <div>
                        {e?.name_package === "GIFT VOUCHER 1" && (
                          <img src={Valley} />
                        )}
                        {e?.name_package === "GIFT VOUCHER 2" && (
                          <img src={Desert} />
                        )}
                        {e?.name_package === "GIFT VOUCHER 3" && (
                          <img src={Water} />
                        )}
                        {e?.name_package === "GIFT VOUCHER 4" && (
                          <img src={Snow} />
                        )}
                        <img src={e?.img2} alt="" />
                      </div>
                    </div>
                    <Voucher.Bottom>
                      <SVG name="vfc" />
                      <button>  {e?.status === "REJECTED" ? "REFUND" : e?.status}</button>
                    </Voucher.Bottom>
                  </Voucher.item2>

                  {e?.name_package === "GIFT VOUCHER 1" && (
                    <SVG name="virtual/VirtualVIP" />
                  )}
                  {e?.name_package === "GIFT VOUCHER 2" && (
                    <SVG name="virtual/VirtualSilver" />
                  )}
                  {e?.name_package === "GIFT VOUCHER 3" && (
                    <SVG name="virtual/VirtualPlatinum" />
                  )}
                  {e?.name_package === "GIFT VOUCHER 4" && (
                    <SVG name="virtual/VirtualGold" />
                  )}
                </Voucher.main>
                // <Items.Main key={i} data-aos="flip-left" data-aos-offset="30">
                //   <Items.Name>{e?.name_package}</Items.Name>
                //   {e?.name_package === "Virtual VIP" && (
                //     <SVG name="virtual/VirtualVIP" />
                //   )}
                //   {e?.name_package === "Virtual Gold" && (
                //     <SVG name="virtual/VirtualGold" />
                //   )}
                //   {e?.name_package === "Virtual Platinum" && (
                //     <SVG name="virtual/VirtualPlatinum" />
                //   )}
                //   {e?.name_package === "Virtual Silver" && (
                //     <SVG name="virtual/VirtualSilver" />
                //   )}
                //   <Items.Location>{e?.name_location}</Items.Location>
                //   <Items.Bottom>
                //     <Items.Date>{e?.date_start.substring(0, 10)}</Items.Date>
                //     <Items.Profit>{e?.date_end.substring(0, 10)}</Items.Profit>
                //   </Items.Bottom>
                //   <Items.Bottom>
                //     {/* <Items.Price>
                //       ${formatMoney(String(fixedNumber(e.price_package, 2)))}
                //     </Items.Price> */}
                //     <Items.Status
                //       className={
                //         e?.status === "REJECTED" ? "REFUND" : e?.status
                //       }
                //     >
                //       {/* {e?.active === 1 ? "ACTIVE" : "REFUND"} */}
                //       {e?.status === "REJECTED" ? "REFUND" : e?.status}
                //     </Items.Status>
                //     {/* <Items.Buy onClick={() => hanldeBuyNFT(e?.id)}>
                //       BUY NOW
                //     </Items.Buy> */}
                //   </Items.Bottom>
                // </Items.Main>
              ))}
            
            </Content>
          </>
        )}
      </ContainerStyled>
      <img src={bg} alt="" />
    </Section>
  );
};
export default VirtualMachine;
