import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div>
      <div
        style={{
     
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};
export default Dashboard;
