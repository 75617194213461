import { themes } from "common/styles/theme";
import styled from "styled-components";

// import { themes } from "common/styles/theme";

export const AccountMain = styled.div`
  min-height: 80vh;
`;

export const ButtonStyled = styled.div`
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  > button {
    width: 171px;
    height: 47px;
    font-weight: bold;
    background: linear-gradient(88.73deg, #00297b 5.9%, #2afcff 99.35%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 23.5px;
    color: white;
    > a {
      &:hover {
        color: #ca111a !important;
      }
    }
  }
`;
