import Route from "./routes";
import fontawesome from "@fortawesome/fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { useLoadScript } from "@react-google-maps/api";
import Aos from "aos";
import "aos/dist/aos.css";
import "common/styles/index.scss";
import "nprogress/nprogress.css";
import { useEffect } from "react";
// import AnimatedCursor from "react-animated-cursor";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "redux/stores";

fontawesome.library.add(faStar);

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 800, delay: 350 });
    Aos.refresh();
  }, []);
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: "AIzaSyDWTx7bREpM5B6JKdbzOvMW-RRlhkukmVE", // Add your API key
    // googleMapsApiKey: "AIzaSyC4lYqEOBOfFU1rotNQYii_iPgtJLpxxbE",
    googleMapsApiKey: "AIzaSyDTzbLOqVR7tj8BLpci3r-1H0MfyXSy-6M",

    // AIzaSyD4N1FPduinqlyBsUgh3QhmiI0dUs8Cmoo// Add your API key
  });

  return (
    <Provider store={store}>
      {/* <AnimatedCursor
        innerSize={12}
        outerSize={16}
        color="167, 163, 231"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={4}
      /> */}
      
      <Route />
    </Provider>
  );
};

export default App;
