import fb from "assets/svg/social/fb.svg";
import ins from "assets/svg/social/ins.svg";
import tele from "assets/svg/social/tele.svg";
import tw from "assets/svg/social/tw.svg";
import ytb from "assets/svg/social/ytb.svg";

export const Links = [
  {
    image: tele,
    link: "https://t.me/tobenft",
  },
  {
    image: tw,
    link: "https://twitter.com/tobenfts/",
  },

  {
    image: fb,
    link: "https://www.facebook.com/profile.php?id=100084102934272",
  },
];
