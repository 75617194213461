import { Overlay } from "../auth";
import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";

const initialState = {
  getLoadding: false,
  listLocation: [],
  locationDetail: [],
  listCategory: [],
  listCountry: [],
  listProvince: [],
  createLocation: [],
  listLocationMachine: [],
};

export const handleGetLocation = (data) => {
  const { latiude, longitude, kilometers, limit, type } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res;
      if (type) {
        if (latiude && longitude) {
          res = await CreateApi.CreateApiGET(
            `public/minning/check-locate?latiude=${latiude}&longitude=${longitude}&kilometers=${kilometers}&limit=${limit}&type=${type}`,
            true
          );
        } else {
          res = await CreateApi.CreateApiGET(
            `public/minning/check-locate?kilometers=${kilometers}&limit=${limit}&type=${type}`,
            true
          );
        }
      } else {
        if (latiude && longitude) {
          res = await CreateApi.CreateApiGET(
            `public/minning/check-locate?latiude=${latiude}&longitude=${longitude}&kilometers=${kilometers}&limit=${limit}`,
            true
          );
        } else {
          res = await CreateApi.CreateApiGET(
            `public/minning/check-locate?kilometers=${kilometers}&limit=${limit}`,
            true
          );
        }
      }

      if (res?.data?.status == true) {
        dispatch(ListLocation(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

export const handleGetLocationDetail = (data) => {
  const { locationid } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));
    dispatch(LocationDetail([]));
    try {
      let res = await CreateApi.CreateApiGET(
        `public/minning/detail/location?locationid=${locationid}`,
        true
      );
      if (res?.data?.status == true) {
        dispatch(LocationDetail(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

export const handleGetListCategory = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiGET(`public/country/place`, true);
      if (res?.data?.status === true) {
        dispatch(ListCategory(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};
export const handleGetListCountry = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiGET(`public/country/list`, true);
      if (res?.data?.status == true) {
        dispatch(ListCountry(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

export const handleGetListProvince = (data) => {
  const { value } = data || "";
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiGET(
        `public/country/list?type=${value}`,
        true
      );
      if (res?.data?.status == true) {
        dispatch(ListProvince(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleSearchLocation = (data) => {
  const { cateloryid, locationid, keywords, limit, type } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res;
      if (type) {
        res = await CreateApi.CreateApiGET(
          `public/minning/place-location?cateloryid=${cateloryid}&locationid=${locationid}&keywords=${keywords}&limit=all&type=${type}`,
          true
        );
      } else {
        res = await CreateApi.CreateApiGET(
          `public/minning/place-location?cateloryid=${cateloryid}&locationid=${locationid}&keywords=${keywords}&limit=all`,
          true
        );
      }

      if (res?.data?.status == true) {
        if (res?.data?.data?.length > 0) {
          dispatch(ListLocation(res?.data?.data));
        } else {
          ErrorMessage("No suitable data found!");
        }
      } else {
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};
export const handleSearchNFTLocationMachine = (data) => {
  const { cateloryid, locationid, keywords, limit, packageid } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/get-reservation?cateloryid=${cateloryid}&locationid=${locationid}&keywords=${keywords}&packageid=${packageid}&limit=all`,
        true
      );
      if (res?.data?.status == true) {
        if (res?.data?.data?.length > 0) {
          dispatch(ListLocationMachine(res?.data?.data));
        } else {
          ErrorMessage("No suitable data found!");
        }
      } else {
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleCreateLocation = (data, navigate) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        `public/minning/save-location`,
        data,
        true
      );
      if (res?.data?.status == true) {
        dispatch(CreateLocation(res?.data?.data));
        dispatch(Overlay(""));
        SuccessMessage("Successfully !");
        navigator.geolocation.getCurrentPosition(function (position) {
          const data = {
            latiude: position.coords.latitude,
            longitude: position.coords.longitude,
            kilometers: 50,
          };
          dispatch(handleGetLocation(data));
        });
        setTimeout(() => {
          // navigate("/maps");
          window.location.href = "/maps";
        }, 3000);
      } else {
        ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

const mapsSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {
    GetLoading: (state, action) => {
      return {
        ...state,
        getLoadding: action.payload,
      };
    },
    ListLocation: (state, acction) => {
      return {
        ...state,
        listLocation: acction.payload,
      };
    },
    ListLocationMachine: (state, acction) => {
      return {
        ...state,
        listLocationMachine: acction.payload,
      };
    },
    LocationDetail: (state, acction) => {
      return {
        ...state,
        locationDetail: acction.payload,
      };
    },
    ListCategory: (state, acction) => {
      return {
        ...state,
        listCategory: acction.payload,
      };
    },
    ListCountry: (state, acction) => {
      return {
        ...state,
        listCountry: acction.payload,
      };
    },
    ListProvince: (state, acction) => {
      return {
        ...state,
        listProvince: acction.payload,
      };
    },
    CreateLocation: (state, acction) => {
      return {
        ...state,
        createLocation: acction.payload,
      };
    },
  },
});

const { reducer, actions } = mapsSlice;
export const {
  GetLoading,
  ListLocation,
  LocationDetail,
  ListCategory,
  ListCountry,
  ListProvince,
  CreateLocation,
  ListLocationMachine,
} = actions;
export default reducer;
