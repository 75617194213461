import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = {

    animation:styled.div`
    transition: all 0.5s;
    transform-origin: ${props => props.ac ? 'center':"bottom"};
    `,
   overlay:styled.div`
    position:fixed;
    width:100vw;
    height:100vh;
    background:none;
    z-index:99;
   
   `
};

