import { AccountMain, Top, Historys, ButtonStyled } from "./styles";
import { CircularProgress } from "@mui/material";
import bg from "assets/lottie/banner.gif";

import { fixedNumber, formatMoney } from "components/FormatNumber";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { handleGetListingFund, handleGetPromotion } from "redux/slices/ICO";
import { handleListPackage } from "redux/slices/staking";
import { GetUser } from "utits/auth";

function Promotion() {
  const user = GetUser();
  const dispatch = useDispatch();

  const { getPromotion, getLoading } = useSelector((state) => state.ico);
  const { listPackage } = useSelector((state) => state.staking);

  useEffect(() => {
    dispatch(handleGetPromotion());
    dispatch(handleListPackage());
  }, []);

  if (!user) return <Navigate to="/" />;

  return (
    // <AccountMain style={{ background: `url(${Bg})`, backgroundSize: "cover" }}>
    <AccountMain>
      <div style={{ width: "100%" , position:'relative'}}>
        <img src={bg} alt="" style={{ width: "100%" }} />
        <ButtonStyled>
          <button ><a href="https://tobenft.net/promotion.pdf" target="_blank">Details</a></button>
        </ButtonStyled>
      </div>
      {/* <div className="container" style={{}}>
        <Top.main>
          <Historys.context>
            <Historys.title>PROMOTION PROGRAM</Historys.title>
            <Historys.itemTitle>
              <div style={{ flex: 1 }}>
                {" "}
                <b>TOTAL NUMBER OF PACKAGES</b>
              </div>
              <div style={{ flex: 1 }}>
                <b>PACKAGE</b>{" "}
              </div>
              <div style={{ flex: 1 }}>
                <b>BONUS </b>
              </div>
            </Historys.itemTitle>
            <div style={{ minHeight: "250px" }}>
              {getLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress />{" "}
                </div>
              ) : getPromotion?.length > 0 ? (
                getPromotion?.map((e, i) => (
                  <Historys.item key={i}>
                    <div style={{ flex: 1 }}>
                      {formatMoney(String(fixedNumber(e?.amount_package, 2)))}
                    </div>

                    {e?.amount_package === 15 && (
                      <div style={{ flex: 1 }}>5,000 USDT</div>
                    )}
                    {e?.amount_package === 10 && (
                      <div style={{ flex: 1 }}>10,000 USDT</div>
                    )}
                    {e?.amount_package === 5 && (
                      <div style={{ flex: 1 }}>25,000 USDT</div>
                    )}

                    <div style={{ flex: 1, color: "yellow" }}>
                      {formatMoney(String(fixedNumber(e?.amount_swb, 2)))} TBC
                    </div>
                  </Historys.item>
                ))
              ) : (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  Empty data...
                </div>
              )}
            </div>
          </Historys.context>
        </Top.main>
      </div> */}
    </AccountMain>
  );
}

export default Promotion;
