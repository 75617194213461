import { data } from "./data";
import { Wrapper } from "./styled";
import menuDes from "assets/svg/menuDes.svg";
import React, {  useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MenuDrawer } from "redux/slices/menu";

export default function MenuDesk() {
  const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [active, setActive] = useState(false)
  
    const handleLink = (e,i) => {
            dispatch(MenuDrawer(e.link));
            navigate(e.link)
    }
  

    const Open = () => {
        setActive(!active)
    }
  return (
    <>
      {data?.map((e, i) => {
                    const isActive = e.link === location.pathname;
          return(
            <div key={i}>
            <Wrapper.animation
              className={e.class}
              ac={active}
              onClick={() => handleLink(e,i)}
            >
              <div className={e.color} >
                <div className={e.text} style={{color:`${isActive ? '#ca111a': 'white'}`}}>{e?.title} </div>
              </div>
              </Wrapper.animation>
              {active &&  <Wrapper.overlay onClick={() => setActive(false)}></Wrapper.overlay>}
             
             
            </div>
          )
      }
      
      )}

      <div className="menu" onClick={Open}>
        <img src={menuDes} alt="" className="hamburger" />
      </div>
    </>
  );
}
