import styled from "styled-components";

export const NFTLocationStyles = {
  Main: styled.div`
    text-align: center;
    padding: 20px 0;
    // box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
    position: relative;
    max-width: 100vw;
    overflow: hidden;
  `,
  Title: styled.div`
    opacity: 0.8;
    color: #bb97ff;
    font-size: 36px;
    font-weight: lighter;
  `,
  Content: styled.div`
    opacity: 0.6;
    color: #bb97ff;
    font-size: 16px;
    font-weight: normal;
  `,
  Container: styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: space-between;
    column-gap: 20px;
  `,
};

export const Item = {
  Main: styled.div`
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
border-radius:10px;
    min-width: 270px;
    flex: 1;
    overflow: hidden;
    z-index: 2;
  `,
  Container: styled.div`
    overflow: hidden;
    height: 300px;
  `,
  Head: styled.div`
    height: 300px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    background-size: cover !important;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
  Image: styled.img``,
  Category: styled.div`
    background-color: #02FFAC;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 800;
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #022C44;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  `,
  Name: styled.p`
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
    color: #fff;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 5px;
  `,
  Address: styled.p`
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    color: white;
  `,
  Rating: styled.div``,

  Content: styled.div`
    text-align: left;
    padding: 20px;
    z-index: 1;
  `,
  Overlay: styled.div`
    background: linear-gradient(to bottom, transparent 0%, black 100%);\
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .7;

  `,
  Ratting: styled.div``,
  Bottom: styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
  }
  `,
  Detail: styled.button`
    border: none;
    outline: none;

    cursor: pointer;
    color: white;
    background: linear-gradient(90deg, #0d7822 0%, #78ec01 100%);
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
  Price: styled.p`
    margin: 8px;
    background: linear-gradient(115.36deg, rgba(25, 113, 84, 0.4) 33.92%, rgba(8, 2, 28, 0) 99.86%);
    border: 1px solid rgba(93, 226, 176, 0.4);
    padding: 5px 10px;
    border-radius: 15px;
    color: white;
    font-weight: bold;
  `,
  Buy: styled.button`
    border: 1px solid #02FFAC;
    outline: none;
    color: inherit;
    cursor: pointer;
    color: white;
    background: linear-gradient(92.98deg, #007E95 15.54%, rgba(2, 255, 172, 0) 93.19%);
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
  Cancel: styled.button`
    border: none;
    outline: none;
    color: inherit;
    cursor: pointer;
    color: white;
    background: linear-gradient(90deg, #780d23 0%, #ec8701 100%);
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
};
export const Pagi = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const NoData = styled.div`
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px #ff0000;
  margin: auto;
`;
