import { lazy } from "react";
import { PATH } from "routes/PATH";

const MapHome = lazy(() => import("pages/MapHome"));
const MapsDetails = lazy(() => import("pages/MapsDetails"));


export const maps = [
  {
    name: "Maps",
    path: PATH.MAPS.MAPS,
    isPrivate: false,
    component: <MapHome />,
  },
  {
    name: "Maps Detail",
    path: PATH.MAPS.MAPS_DETAIL,
    isPrivate: false,
    component: <MapsDetails />,
  },
];
