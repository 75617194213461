import {
  Section,
  Content,
  Item,
  ContainerStyled,
  Text,
  TextItem,
  BgNft,
  StyledButton,
  Image,
  ItemFlex,
  Overlay,
  Nft,
} from "./styles";
import { CircularProgress } from "@mui/material";
import BgNftClassic from "assets/NFT/bg-nft-classic.svg";
import Vector from "assets/img/Vector.svg";
import loadingG from "assets/img/loadingg.gif";
import NftItem1 from "assets/svg/NftItem.svg";
import bg from "assets/img/background.png";
import bg2 from "assets/img/background2.png";
import QR from "assets/svg/qrcode.svg";

import BgNftGold from "assets/svg/bg-nft-gold.svg";
import BgNftPlatinum from "assets/svg/bg-nft-platinum.svg";
import BgNftSilver from "assets/svg/bg-nft-silver.svg";
import BgNftVip from "assets/svg/bg-nft-vip.svg";
import EmptyData from "components/EmptyData";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleGetMyNftCard } from "redux/slices/nft";
import { Spin } from "antd";
import SVG from "components/SVG";

const Card = () => {
  const dispatch = useDispatch();
  const { listMyNftCard, loadding } = useSelector((state) => state.nft);
  useEffect(() => {
    dispatch(handleGetMyNftCard());
  }, []);
  return (
    <>
      {" "}
      <Section>
      <img src={bg} alt="" />
        <ContainerStyled>
          {!listMyNftCard ? (
            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
              <img src={loadingG} alt="" style={{ width: "15%" }} />
            </div>
          ) : (
            <>
              <Content>
                {listMyNftCard?.total === 0 && <EmptyData />}
                {listMyNftCard?.data?.map((e, i) => (
                   <Nft.main>
                   <Nft.item>
                     <div>
                       <SVG name="vfc" />
                       <img src={QR} alt="" />
                     </div>
                     <div>
                       <div>
                         <span>{e?.name}</span>
                         <strong>${e?.price}</strong>
                       </div>
                       <div style={{ display: "flex", alignItems: "center" , fontWeight:700, color:"#007E95"}}>
                         {" "}
                         {e?.created_at.substring(0, 10)}
                         {/* {loadding ? 
                         <button ><Spin style={{marginTop:'5px'}}/></button>:  <button onClick={() => submit(e?.id)}>Buy ID</button>} */}
                       </div> 
                     </div>
                   </Nft.item>
                   <Nft.item2>
                     <div>
                       <SVG name="arrowRight" />
                       <span>
                         <strong>{e.percent}</strong>%
                       </span>
                       <p>monthly</p>
                     </div>
                     <div>
                       <SVG name="arrowRight" />
                       <strong>24</strong>
                       <p>months</p>
                     </div>
                   </Nft.item2>
                   {e.name === "Apartments" && 
                   <img src={BgNftClassic} alt="" />
                  }
                   {e.name === "Townhouses" && 
                   <img src={BgNftSilver} alt="" />
                  }
                  {e.name === "Shophouse" && 
                   <img src={BgNftGold} alt="" />
                  }
                  {e.name === "Villa" && 
                   <img src={BgNftPlatinum} alt="" />
                  }
                    {e.name === "Resort" && 
                   <img src={BgNftVip} alt="" />
                  }
                 </Nft.main>
                  // <Item data-aos="flip-left" data-aos-offset="30" key={i}>
                  //   <Text>
                  //     <TextItem>
                  //       {e?.name}
                  //       <br></br> ${e.price}
                  //     </TextItem>
                  //     <div>
                  //       <div>
                  //         <img src={Vector} alt="" /> 24 months
                  //       </div>
                  //       <div>
                  //         <img src={Vector} alt="" /> {e.percent}% / monthly
                  //       </div>
                  //     </div>
                  //   </Text>
                  //   <ItemFlex>
                  //     <Image
                  //       style={{
                  //         width: "87px",
                  //         textAlign: "center",
                  //         padding: "10px 0",
                  //       }}
                  //     >
                  //       <img
                  //         src={NftItem1}
                  //         alt=""
                  //         style={{ maxHeight: "220px" }}
                  //       />

                  //       {/* <img src={e?.img} alt="" style={{ maxHeight: "220px" }} /> */}
                  //     </Image>
                  //     <div
                  //       style={{
                  //         display: "flex",
                  //         alignItems: "center",
                  //         flexDirection: "column",
                  //         gap: "20px",
                  //       }}
                  //     >
                  //       <span>{e?.created_at.substring(0, 10)}</span>
                  //     </div>
                  //   </ItemFlex>

                  //   {e.name === "Classic" && (
                  //     <>
                  //       <Overlay bg={"#9B0A5C"}></Overlay>
                  //       <BgNft>
                  //         <img src={BgNftClassic} alt="" />
                  //       </BgNft>
                  //     </>
                  //   )}
                  //   {e.name === "Silver" && (
                  //     <>
                  //       <Overlay
                  //         bg={
                  //           "linear-gradient(268.17deg, #D3D4D4 6.81%, #536981 99.24%)"
                  //         }
                  //       ></Overlay>
                  //       <BgNft>
                  //         <img src={BgNftSilver} alt="" />
                  //       </BgNft>
                  //     </>
                  //   )}
                  //   {e.name === "Gold" && (
                  //     <>
                  //       <Overlay
                  //         bg={
                  //           "linear-gradient(90.67deg, #FEC874 3.8%, #F5D087 95.98%)"
                  //         }
                  //       ></Overlay>
                  //       <BgNft>
                  //         <img src={BgNftGold} alt="" />
                  //       </BgNft>
                  //     </>
                  //   )}
                  //   {e.name === "Platinum" && (
                  //     <>
                  //       <Overlay
                  //         bg={
                  //           "linear-gradient(269.77deg, #FEBDA3 0.26%, #E39B9D 89.14%)"
                  //         }
                  //       ></Overlay>
                  //       <BgNft>
                  //         <img src={BgNftPlatinum} alt="" />
                  //       </BgNft>
                  //     </>
                  //   )}
                  //   {e.name === "VIP NOW" && (
                  //     <>
                  //       <Overlay
                  //         bg={
                  //           "linear-gradient(88.34deg, #1A2A43 5.64%, #130E25 99.53%)"
                  //         }
                  //       ></Overlay>
                  //       <BgNft>
                  //         <img src={BgNftVip} alt="" />
                  //       </BgNft>
                  //     </>
                  //   )}
                  // </Item>
                ))}
              </Content>
            </>
          )}
        </ContainerStyled>
        <img src={bg2} alt="" />

      


      </Section>
    </>
  );
};
export default Card;
