import styled from "styled-components";

export const Banners = styled.div``;
export const Title = styled.div`
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
  > h2 {
    color: white;
    font-size: 32px;
    font-weight: bold;
    font-family: IBM Plex Sans;
  }
  > p {
    color: white;
  }
`;

export const Voucher = {
  main: styled.div `
  width: 525px;
  height: 233px;
  position: relative;
  display: flex;
  > img {
    top: 0;
    position: absolute;
    z-index: -1;
  }
`,
  item: styled.div `
  width: 92px;
  font-size: 32px;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: white;
  > div {
    transform: rotate(-90deg);
    font-weight: 600;
  }
`,
  item2: styled.div `
  width: 433px;
  > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
`,
  Top: styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`,
  Title: styled.div `
  font-size: 26px;
  color: #007e95;
  font-family: "Jockey One";
`,
  Text: styled.div `
  display: flex;
  align-items: start;
  width: 100%;
  gap: 10px;
  > div {
    display: flex;
    gap: 10px;
    color: #007e95;
    > span {
      > strong {
        font-size: 30px;
        font-weight: 400;
      }
    }
    > img {
      position: relative;
      top: -15px;
    }
  }
`,
  Bottom: styled.div `
  position: relative;
  top: -35px;
  > img {
    height: 35px;
  }
  > button {
    position: relative;
  left: -10px;
    width: 136px;
    height: 33px;
    background: linear-gradient(88.73deg, #9df75a 5.9%, #02ffac 99.35%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 23.5px;
  }
`,
};
export const Background= styled.div`
height: 560px;
width:100%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    gap: 5px;
    @media(max-width:600px){
      height:400px;
    }
    >img{
      position:absolute;
      height: 100%;
    }
`
export const Banner = {
  main: styled.section`
    width: 100%;
    background: rgba(1, 33, 51, 0.7);
  `,
  item: styled.div`
    display: flex;
    align-items: center;
    color: white;
    gap: 50px;
    padding: 20px;
    >img{
      max-width:150px;
      width:100%;
    }
    @media(max-width:550px){
      gap: 20px;
      >img{
        max-width:100px;
      }
      }
    > div {
      > span {
        font-size: 32px;
        font-weight: bold;
        font-family: IBM Plex Sans;
        @media(max-width:550px){
        font-size: 20px;
        }
      }
      >p{
        @media(max-width:550px){
          font-size: 10px;
          }
      }
    }
  `,
};
export const MenuLink = {
  main: styled.section`
    width: 100%;
    background: rgba(1, 33, 51, 0.7);
  `,
  item: styled.div`
    display: flex;
    align-items: center;
    color: white;
    gap: 50px;
    padding: 10px;
    @media(max-width:600px){
      padding:10px 0;
      justify-content:center;
      flex-wrap:wrap;
      gap:10px;
    }
    >div{
      padding: 10px 30px;
      // background: linear-gradient(90.96deg, #01FB91 5.16%, #29FCFC 93.93%);
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius:10px;
      font-weight:bold;
    }
  `,
  active:styled.div`
  background:${props => props.ac ? 'linear-gradient(90.96deg, #01FB91 5.16%, #29FCFC 93.93%)': "linear-gradient(92.98deg, #007E95 15.54%, rgba(2, 255, 172, 0) 93.19%)"};
  color:${props => props.ac ? '#007E95': 'white'} ;
  
  `
}