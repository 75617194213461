import { Links, ListProducts, Privacy } from "./data";
import {
  Address,
  ContainerStyled,
  Context,
  LinkMain,
  Section,
  Warraper,
  WarraperAddress,
  WarraperLink,
} from "./footer.styled";
import bg from "assets/svg/bg-footer.svg";
// import Image from "assets/svg/logoFooter.svg";
import Image from "assets/svg/vfc.svg";

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Section>
      <ContainerStyled>
        <Warraper>
          <WarraperAddress data-aos="fade-up" data-aos-offset="30">
            <div style={{ textAlign: "center" }}>
              <img src={Image} alt="" />
            </div>
            <Address>
              <div style={{ textAlign: "center" }}>
                Copyright 2022 TOBE NFT
              </div>
            </Address>
          </WarraperAddress>
          <WarraperLink data-aos="zoom-in-up" data-aos-offset="30">
            {/* <Context>
              <span>Products</span>
              <LinkMain>
                {ListProducts?.map((e, i) => (
                  <span key={i}>{e?.text}</span>
                ))}
              </LinkMain>
            </Context> */}
            <Context>
              <span>About us</span>
              <LinkMain>
                {Privacy?.map((e, i) => (
                  <Link to={e.link} key={i}>
                    {e?.text}
                  </Link>
                ))}
              </LinkMain>
            </Context>
            <Context>
              <span>Community</span>
              <LinkMain
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3,1fr)",
                  gap: "10px",
                }}
              >
              {Links?.map((e, i) => (
                  <a href={e.link} target="_blank">
                  <img
                    key={i}
                    src={e?.image}
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                  />
                  </a>
                ))}
              </LinkMain>
            </Context>
          </WarraperLink>
        </Warraper>
      </ContainerStyled>
    </Section>
  );
}

export default Footer;
