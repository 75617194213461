import { themes } from "common/styles/theme";
import styled from "styled-components";

export const NFTLocationStyles = {
  Main: styled.div`
    text-align: center;
    padding: 20px 0;
    // box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
    position: relative;
    max-width: 100vw;
    overflow: hidden;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;
  `,
  Title: styled.div`
    opacity: 0.8;
    color: #bb97ff;
    font-size: 36px;
    font-weight: lighter;
  `,
  Content: styled.div`
    opacity: 0.6;
    color: #bb97ff;
    font-size: 16px;
    font-weight: normal;
  `,
  Container: styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: center;
    column-gap: 20px;
  `,
};


export const Pagi = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Wrapper = {
  main: styled.div`
    min-width: 270px;
    max-width: 270px;
    border: 1px solid #02FFAC;
    border-radius: 18px;
    background: rgba(6, 100, 153, 0.3);
  `,

  title: styled.div`
    color: white;
    ${themes.mixin.flexColCenter()};
    justify-content: space-between;
    padding:10px;
    > h2 {
      color: #32fea2;
      margin: 0;
    }
    >div{
      >p{
        font-size:12px;
      }
    }
    
  
  `,

  Ratting:styled.div`
  
  
  display:flex;
  gap:3px;
  `,

  content: styled.div`
    text-align: start;
    padding:10px;
    > h3 {
      color: white;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size:16px;
    }
    >span{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size:12px;
    }
    color: white;
  `,
  Bottom: styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
  `,
  Detail: styled.button`
    border: none;
    outline: none;

    cursor: pointer;
    color: white;
    background: linear-gradient(115.36deg, #146A4E 33.92%, #017479 99.86%);
    border: 1px solid rgba(106, 49, 129, 0.2);
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
  Buy: styled.button`
    border: none;
    outline: none;
    color: inherit;
    cursor: pointer;
    color: white;
    background: linear-gradient(92.98deg, #007E95 15.54%, rgba(2, 255, 172, 0) 93.19%);
    border: 1px solid #02FFAC;

    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
};
