import NFTLocation from "./NFTLocation";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleGetMyRealEstate } from "redux/slices/nft";

const RealEstate = () => {
  return (
    <>
      <NFTLocation />
    </>
  );
};
export default RealEstate;
