import styled from "styled-components";

export const Wrapper = {
  Main: styled.div`
    margin: auto;
    background: rgba(6, 100, 153, 0.3);
    border-radius: 18px;
   
    .css-1s2u09g-control {
      border-radius: 10px;
      height: 44px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
      color:white;
   
    }
  .css-14el2xx-placeholder{
    padding:6px;
  }
    .css-qc6sy-singleValue{
      color:white;
      padding:6px;
    }
    .rti--container {
      border-radius: 10px;
      min-height: 44px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
      color:white;
    }
    .css-1pahdxg-control {
      border-radius: 10px;
      height: 44px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
      color:white;
    }
    .rti--input{
      background: none;
      color:white;
    }
    .rti--tag{
      background: linear-gradient(115.36deg, rgba(2, 255, 172, 0.4) 33.92%, rgba(0, 126, 149, 0.4) 99.86%);
      >span{
        color:white;
      }
      >button{
        color:white;
      }
    }
  `,
  Title: styled.div`
    color: white;
    width: 100%;

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    font-weight: 700;
    text-shadow: 2px 2px #b11414d1;
  `,
  Context: styled.div`
    @media (max-width: 767px) {
      padding: 20px;
    }
    display: flex;
    padding: 20px 50px;
    flex-direction: column;
    gap: 20px;
    > input {
      width: 98%;
      padding: 5px 15px;
      border-radius: 10px;
      height: 37px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
      color:white;

    }

    > textarea {
      padding: 5px 15px;
      border-radius: 20px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.5);
      color:white;
    }
  `,
  Submit: styled.button`
    font-weight: 500;
    color: #007E95;
    font-size: large;
    background: #02FFAC;
    border: 1px solid rgba(255, 255, 255, 0.16);
    padding: 5px 50px;
    width: fit-content;
    border-radius: 30px;
    margin: auto;
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
    &:hover {
      transition: 0.7s;
      background: linear-gradient( 115.36deg, rgba(2,255,172,0.4) 33.92%, rgba(0,126,149,0.4) 99.86% );
      color:white;
    }
  `,
  Des: styled.p`
    color: white;
    text-align: center;
    padding: 20px 30px;
  `,
};

export const ListImage = {
  Main: styled.div`
    height: 107px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
  `,
  Item: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    height: 100%;
    min-width: 90px;
    max-width: 90px;
    background: #6d6a7161;
    border-radius: 5px;

    > input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    > svg {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ffebcd7d;
      border-radius: 50%;
      cursor: pointer;
    }
  `,
};
export const Address = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
  > input {
    width: 98%;
    padding: 5px 15px;
    border-radius: 10px;
    height: 44px;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color:white;

  }
  > textarea {
    padding: 5px 15px;
    border-radius: 10px;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color:white;
  }
`;

export const OpenHours = {
  Container: styled.div`
    max-height: 150px;
    overflow-y: scroll;
    margin-bottom: 10px;
  `,
  Main: styled.div`
    width: 100%;
    display: flex;
    gap: 30px;
    padding: 0px 20px;
    align-items: center;
    margin-bottom: 5px;
  `,
  Time: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    width: 100%;
    > input {
      width: 100%;
      padding: 3px 10px;

      border-radius: 5px;
      color: black;
    }
  `,
  Text: styled.div`
    font-size: 14px;
  `,
  DayOfWeek: styled.div`
    color: white;
    font-weight: bold;
    min-width: 30px;
    text-transform: capitalize;
  `,
};

export const Option = {
  Main: styled.div`
    display: flex;
    color: white;
    width: 100%;
    align-items: center;
    gap: 30px;
  `,
  Radio: styled.div`
    display: flex;
    gap: 30px;
  `,
  Text: styled.div`
    font-weight: bold;
  `,
};

export const Price = styled.div`
  color: white;
  display: flex;
  justify-content: space-around;
  background: linear-gradient(
    115.36deg,
    rgba(2, 255, 172, 0.4) 33.92%,
    rgba(0, 126, 149, 0.4) 99.86%
  );
  padding: 10px 20px;
  border-radius: 10px;
  > p > span {
    color: #eb0274;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
