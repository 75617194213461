import { Address, ListImage, Price, Wrapper } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { ErrorMessage, swalWithBootstrapButtons } from "components/Message";
import Recaptcha from "components/ReCaptcha/Recaptcha";
import RecaptchaF2A from "components/RecaptchaF2A/RecaptchaF2A";
import SVG from "components/SVG";
import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { Overlay } from "redux/slices/auth";
import {
  handleGetListCategory,
  handleGetListCountry,
  handleGetListProvince,
} from "redux/slices/maps";
import USDT from "assets/img/USDT.png";
import NOW from "assets/img/SWBC.png";
import { TwentyOneMp } from "@mui/icons-material";
import { fixedNumber, formatMoney } from "components/FormatNumber";
import { handleGetWallet } from "redux/slices/wallet";
import { GetUser } from "utits/auth";

const FormCreateLocation = ({ position }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const lat = new URLSearchParams(search).get("lat");
  const lng = new URLSearchParams(search).get("lng");
  const { getWallet } = useSelector((state) => state.wallet);

  const { gettokenrep } = useSelector((state) => state.recaptchaF2A);
  const { getLoadding } = useSelector((state) => state.maps);
  const { blockNow } = useSelector((state) => state.ico);
  var queryString = window.location.search;
  var searchParams = new URLSearchParams(queryString);
  const ValueAddress = searchParams.get("address");

  const [features, setFeatures] = useState();
  const [arrImg, setArrImg] = useState([]);
  const [check2fa, setCheck2fa] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    address: ValueAddress || "",
    description: "",
  });
  const [country, setCountry] = useState();
  const [province, setProvince] = useState();
  const [category, setCategory] = useState();
  const [selectedProvince, setSelectedProvince] = useState({});
  const [type, setType] = useState();
  const { listCategory, listCountry, listProvince } = useSelector(
    (state) => state.maps
  );

  const user = GetUser();

  useEffect(() => {
  
    if (user) {
      dispatch(handleGetWallet());
    }
  }, []);
 const optionCoin = [
   {
    value: "SWBC", label: <div><img src={NOW} height="30px" width="30px"style={{marginRight:'5px'}}/>{  formatMoney(
      String(fixedNumber(getWallet[0]?.swbbalance, 2)) 
    )}  SWBC </div>
   },
   {
    value: "USDT", label:<div><img src={USDT} height="30px" width="30px" style={{marginRight:'5px'}}/> {  formatMoney(
      String(fixedNumber(getWallet[0]?.livebalance, 2)) 
    )} USDT </div>
   },
 ]
  const optionsCate =
    listCategory &&
    listCategory?.map((item) => {
      return { value: item.id, label: item.name };
    });
   
  const optionsCountry =
    listCountry &&
    listCountry?.map((item) => {
      return { value: item.iso2, label: item.name, id: item.id };
    });

  const optionsProvince =
    listProvince &&
    listProvince?.map((item) => {
      return { value: item.iso2, label: item.name, id: item.id };
    });

  const { overlay } = useSelector((state) => state.auth);

  const handleChangeImage = (e) => {
    const { files, name } = e.target;
    setArrImg([...arrImg, ...files]);
  };

  const removeImage = (img) => {
    let arr = arrImg;
    arr.splice(arr.indexOf(img), 1);
    setArrImg([...arr]);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  // const changeOptionOpen = (e) => {
  //   const { value } = e.target;

  //   setOpenHours(value);
  // };
  console.log(payload);
  useEffect(() => {
    dispatch(handleGetListProvince(country));
    setSelectedProvince([]);
  }, [country]);
  useEffect(() => {
    dispatch(handleGetListCategory());
    dispatch(handleGetListCountry());
  }, []);
  const handleSubmit = () => {
    if (!gettokenrep) {
      ErrorMessage("Invalid Recaptcha");
    } else {
      if (
        payload.name &&
        payload.address &&
        // payload.phone &&
        // payload.email &&
        payload.description &&
        arrImg.length > 0 &&
        lat &&
        lng &&
        country.id &&
        province.id &&
        category.value && 
        type.value 
      ) {
        if (arrImg.length > 5) {
          ErrorMessage("You can choose up to 5 photos!");
        } else {
          const data = new FormData();
          data.append("name", payload.name);
          data.append("address", payload.address);
          data.append("description", payload.description);
          for (const key of Object.keys(arrImg)) {
            data.append("images", arrImg[key]);
          }
          data.append("features", features || ["wifi"]);
          data.append("email", payload.email);
          data.append("phone", payload.phone);


          data.append("latiude", lat);
          data.append("longitude", lng);
          data.append("nation", country.id);
          data.append("city", province.id);
          data.append("place", category.value);
          data.append("type", type.value);



          swalWithBootstrapButtons(dispatch, Overlay, data, navigate);
        }
      } else {
        ErrorMessage("Please enter full information !");
      }
    }
  };
  if (!lat || !lng) return <Navigate to="/buy-nft" />;
  return (
    <>
      {" "}
      <Wrapper.Main>
        <Wrapper.Title>Creat a location Metaverse:</Wrapper.Title>
        <Price>
          <b>{ValueAddress}</b>
          {/* <p>
            {" "}
            <span>Latitude: </span> {lat}
          </p>
          <p>
            <span>Longitude: </span>
            {lng}
          </p> */}
        </Price>
        {/* <Wrapper.Des>
          NFT are unique in terms of their traits, making them perfect for
          representing rare items such as real properties. It cannot be divided,
          duplicated, or increased in quantity. There can only be limited amount
          of each type. Thus, the value of each NFT to be increased for the
          following timeline and maximum quantity sold whichever comes first.
        </Wrapper.Des> */}
        {/* <Wrapper.Des>
          NFT are unique in terms of their traits, making them perfect for
          representing rare items such as real properties. It cannot be divided,
          duplicated, or increased in quantity. There can only be limited amount
          of each type. Thus, the value of each NFT to be increased for the
          following timeline and maximum quantity sold whichever comes first.
        </Wrapper.Des> */}
        <div></div>

        <Wrapper.Context>
        <Address>
            <input
              type="text"
              name="name"
              placeholder="Place Name"
              onChange={handleChange}
            />
           
           <div style={{ width: "100%" }}>
              
              <Select
                options={optionsCountry}
                placeholder="Country"
                onChange={(value) => setCountry(value)}
                isClearable={true}
              />
            </div>
          </Address>
          <Address>
            <div style={{ width: "100%" }}>
              <Select
                options={optionCoin}
                placeholder="Choose a coin to pay"
                onChange={(value) => setType(value)}
                isClearable={true}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Price >
                <p>
                  {" "}
                  1 Location NFT ={" "}
                  <span> {blockNow ? blockNow?.price : "..."}</span> USDT
                </p>
              </Price>
            </div>
          </Address>
          <Address>
            <input
              type="text"
              name="email"
              placeholder="email"
              onChange={handleChange}
            />
           
           <div style={{ width: "100%" }}>
           <input
              type="text"
              name="phone"
              placeholder="phone"
              onChange={handleChange}
              style={{ width: "98%",
                padding:" 5px 15px",
                borderRadius: "10px",
                height: "44px",
                background: "none",
                border: "1px solid rgba(255, 255, 255, 0.5)",
                color:"white"}}
            />
            </div>
          </Address>
          

          <Address>
          <div style={{ width: "100%" }}>
              <Select
                options={optionsCate}
                placeholder="Place Type"
                onChange={(value) => setCategory(value)}
                isClearable={true}
              />
            </div>
           
            <div style={{ width: "100%" }}>
              <Select
                autosize={true}
                value={selectedProvince}
                options={optionsProvince}
                placeholder="Province"
                onChange={(value) => {
                  setProvince(value);
                  setSelectedProvince(value);
                }}
                isClearable={true}
              />
            </div>
          </Address>
          <Address>
            <input
              type="text"
              name="address"
              placeholder="Place Address"
              onChange={handleChange}
              defaultValue={payload.address}
              value={ValueAddress}
            />

            <div style={{ width: "100%" }}>
              <TagsInput
                value={features}
                onChange={setFeatures}
                name="features"
                placeHolder="Type each word then press Enter"
              />
            </div>
          </Address>

          <textarea
            name="description"
            id=""
            cols="30"
            rows="5"
            placeholder="About Place"
            onChange={handleChange}
          />
          <ListImage.Main>
            {arrImg &&
              arrImg?.map((item, index) => (
                <ListImage.Item
                  key={index}
                  style={{
                    background:
                      "linear-gradient(115.36deg, rgba(2, 255, 172, 0.4) 33.92%, rgba(0, 126, 149, 0.4) 99.86%)",
                  }}
                >
                  <img src={URL.createObjectURL(item)} alt="" />
                  <CloseIcon onClick={() => removeImage(item)} />
                </ListImage.Item>
              ))}

            <ListImage.Item>
              <div style={{ width: "50%" }}>
                <SVG name="kyc" />
              </div>
              <input
                type="file"
                name="images"
                multiple
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}
              />
            </ListImage.Item>
          </ListImage.Main>

          {!check2fa ? <Recaptcha /> : <RecaptchaF2A />}
          {/* <Price style={{ width: "fit-content" }}>
            <p>
              {" "}
              1 Location NFT ={" "}
              <span> {blockNow ? blockNow?.price : "..."}</span> USDT
            </p>
          </Price> */}
          <Wrapper.Submit onClick={handleSubmit}>
            {" "}
            {getLoadding ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />{" "}
              </div>
            ) : (
              "CREATE NFT"
            )}
          </Wrapper.Submit>
        </Wrapper.Context>
      </Wrapper.Main>
    </>
  );
};
export default FormCreateLocation;
