import { themes } from "common/styles/theme";
import styled from "styled-components";

export const DashboardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  // margin-left: 250px;
  width: 100%;
  z-index: 21;
  // @media (max-width: 1199px) {
  //   margin-left: 0;
  // }
`;

export const OverLay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.5;
  z-index: 21;
  display: none;
  @media (max-width: 991px) {
    display: ${(props) => (props.openDrawer ? "block" : "none")};
  }
`;

export const LinkMain = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 40px);
  gap: 20px;
  padding-left: 15px;
  margin-top: 100px;
  > img {
    cursor: pointer;
  }
`;

// export const MenuDesk = {
//   main: styled.div`
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     width: 316px;
//     height: 308px;
//     z-index: 9999;
//   `,
//   item: styled.div`
//     height: 100%;
//     display: flex;
//     flex-direction: column-reverse;
//     align-items: end;
//     > div {
//       &:nth-child(1) {
//         width: 128px;
//         height: 125px;
//         display: flex;
//         justify-content: flex-end;
//         align-items: center;
//         background: radial-gradient(
//           77.81% 65.08% at 100% 98.67%,
//           rgba(2, 255, 172, 0.4) 0%,
//           rgba(157, 247, 90, 0.4) 45.31%,
//           rgba(0, 126, 149, 0.4) 100%
//         );
//         border-top-left-radius: 90%;
//         > img {
//           margin-right: 10px;
//         }
//       }
//       &:nth-child(2) {
//         width: 192px;
//         height: 187px;
//         display: flex;
//         justify-content: flex-end;
//         align-items: center;
//         background: radial-gradient(
//           77.81% 65.08% at 100% 98.67%,
//           rgba(2, 255, 172, 0.4) 0%,
//           rgba(157, 247, 90, 0.4) 45.31%,
//           rgba(0, 126, 149, 0.4) 100%
//         );
//         border-top-left-radius: 90%;
//         > img {
//           margin-right: 10px;
//         }
//       }
     
//     }
//   `,
// };
