import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { themes } from "common/styles/theme";
import { theme } from "components/styles/Theme";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components";

export const StyledHeader = styled.header `
  background: rgba(0, 0, 0, 0.6);
  // position: ${(props) => (props.bg == false ? "initial" : "fixed")};
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 30;
  transition: all 0.2s;
  display: flex;
  flex-direction: column-reverse;
`;
export const MegaMenu = {
    main: styled.div `
    border-top: 1px solid #756b8e;
    height: 200px;
    position: fixed;
    width: 100%;
    top: 70px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    color: white;
  `,
    wrapper: styled.div `
    ${themes.mixin.flexCenter()};
    padding-top: 15px;
    flex-direction: column;
  `,
    context: styled.div `
    ${themes.mixin.flexCenter()};
    gap: 50px;
  `,
    children: styled.div `
    position: absolute;
    width: 300px;
    display: grid;
    grid-template-columns: repeat(2, 150px);
    font-size: 12px;
    padding: 10px 0;
    z-index: 2;
  `,
};

export const Nav = styled.nav `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
export const Logo = styled.img `
max-height:40px;

`;

export const Menu = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: -120px;
  gap: 10px;
  width: 40%;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Span = styled.a `
  color: ${(props) => (props.bg == false ? "white" : "white")};
  cursor: pointer;
  width: 120px;
  height: 70px;
  ${themes.mixin.flexCenter()};

  > a {
    color: ${(props) => (props.bg == false ? "white" : "white")};
    background: ${(props) =>
      props.active
        ? "linear-gradient(115.36deg, rgba(8, 141, 97, 0.4) 33.92%, rgba(8, 2, 28, 0) 99.86%);"
        : "none"};
    width: 117px;
    height: 33px;
    border-radius: 20px;
    ${themes.mixin.flexCenter()};

    cursor: pointer;
    &:hover {
      color: white !importand;
    }
  }
`;
export const StyledButton = styled.button `
  background: linear-gradient(88.73deg, #9DF75A 5.9%, #02FFAC 99.35%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  border: none;
  width: 118px;
  height: 35px;
  color: #022C44;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const MenuHeader = {
    main: styled.div `
      color:white;
      min-width: 100px;
      max-width: 100px;
      width:100%;
      height: 70px;
 
      ${themes.mixin.flexCenter()};
      position: relative;
      >div{
      ${themes.mixin.flexCenter()};
        width:100%;
        height:30px;
        background: ${props => props.active ? 'linear-gradient(115.36deg, rgba(8, 141, 97, 0.4) 33.92%, rgba(8, 2, 28, 0) 99.86%)': "none"};
      border-radius: 32.5px;
      }
     
    `,
    children: styled.div `
    position: absolute;
    top: 53px;
    left: -30px;
    width: 150px;
    background: rgba(0, 0, 0, 0.2);
    border-radius:10px;
    >div{
      padding:10px;
      >a{
        &.active{
          color:#02FFAC;
        }
      }
    }
    
    `,

}

//moblie
export const NavMoblie = styled.div `
  position: fixed;
  max-height: 450px;
  z-index: 2;
  // background: ${(props) => (props.menu == "active" ? "black" : "black")};
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  left: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: 0;
  
  ${(props) =>
    props.menu == "active" &&
    css`
      height: 100%;
      min-height: 450px;
    `}
`;
export const MenuMoblie = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;
export const NavMoblieItem = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  padding: 10px;
  width:100%;
`;

export const NavMoblieItemChildren = styled.div`
  font-size: 12px;
  margin-left:10px;
  >div{
    >a{
      &.active{
        color:#35b999;
      }
    }
  }
`;
export const Item = styled.li`
  color: white;
  align-items: start;
  display: flex;
  justify-content: start;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  border-bottom:1px solid #a39d9d;
  ${(props) =>
    props.ac  &&
    css`
    >a{
      color: #02FFAC !important;
    }
    `}

  > a {
    cursor: pointer;
    color: white;

    &.active {
      color: #02FFAC;
    }
  }
`;
export const StyledButtonMoblie = styled.button`
  background: linear-gradient(88.73deg,#9DF75A 5.9%,#02FFAC 99.35%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  border: none;
  width: 118px;
  height: 35px;
  color: #022C44;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
`;
export const IconMenu = styled(MenuIcon)`
  color: ${(props) => props.theme.white};
  cursor: pointer;
`;
export const IconClose = styled(CloseIcon)`
  color: ${(props) => props.theme.white};
  cursor: pointer;
`;

export const OverlayScreen = styled.div`
  background: #46435087;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  margin-top: 70px;
`;