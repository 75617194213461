export const data = [
    {
        class:'pie9 pie111',
        color:'pie-color9 pie-color111',
        text:'text111',
        link:'/promotion',
        title:'Promotion',
        
    },
    {
        class:'pie9 pie222',
        color:'pie-color9 pie-color222',
        text:'text222',
        link:'/buy-nft',
        title:'Buy NFT',
      
    },
    {
        class:'pie9 pie333',
        color:'pie-color9 pie-color333',
        text:'text333',
        link:'/minning',
        title:'Mining',
     
       
    },
    {
        class:'pie1 pie11',
        color:'pie-color1 pie-color11',
        text:'text11',
        link:'/my-nft',
        title:'My Assets',
    },
    {
        class:'pie1 pie22',
        color:'pie-color1 pie-color22',
        text:'text22',
        link:'/history',
        title:'History',
      
    },
    {
        class:'pie1 pie33',
        color:'pie-color pie-color33',
        text:'text33',
        link:'/profile',
        title:'Profile',
    },
    {
        class:'pie pie10',
        color:'pie-color pie-color1',
        text:'text1',
        link:'/swap',
        title:'Swap',
    },
    {
        class:'pie pie2',
        color:'pie-color pie-color2',
        text:'text2',
        link:'/account',
        title:'Account',
    },
    {
        class:'pie pie3',
        color:'pie-color pie-color3',
        text:'text3',
        link:'/wallet',
        title:'Wallet',
      
       

    },
    

]