import { GetUserStore, User } from "redux/slices/auth";

const SESSION_KEY = "session";
const USER_KEY = "user";

export const getSession = () => {
    try {
        return JSON.parse(localStorage.getItem(SESSION_KEY));
    } catch (error) {
        return null;
    }
};
export const setSession = (data) => {
    localStorage.setItem(SESSION_KEY, JSON.stringify(data));
};

export const clearSession = () => {
    localStorage.removeItem(SESSION_KEY);
};

export const GetUser = () => {
    try {
        return JSON.parse(localStorage.getItem(USER_KEY));
    } catch (error) {
        return null;
    }
};

export const SetUser = (data, dispatch) => {
    dispatch(GetUserStore(data));
    localStorage.setItem(USER_KEY, JSON.stringify(data));
};

export const ClearUserSession = (dispatch) => {
    dispatch(GetUserStore(""));
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(SESSION_KEY);
};

export const getColor = (rating, index) => {
    if (rating > index) {
        return "#FFD705";
    }
    return "#DCDCDC";
};