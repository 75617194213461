import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, message, Row, Space } from "antd";
import { Collapse } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { ChildrenDrawer, GroupMining } from "redux/slices/mining";

const { Panel } = Collapse;

export default function Popup() {
  const dispatch = useDispatch();

  const { groupMining, childrenDrawer } = useSelector((state) => state.mining);
  
  const [data, setData] = useState([
    {
      id: 0,
      name: "user",
    },
    {
      id: 1,
      name: "user 2",
    },
    {
      id: 2,
      name: "user 3",
    },
    {
      id: 3,
      name: "user 4",
    },
    {
      id: 4,
      name: "user 5",
    },
  ]);


  const [index, setIndex] = useState(4);

  const showChildrenDrawer = () => {
    dispatch(ChildrenDrawer(true));
  };

  const onChildrenDrawerClose = () => {
    dispatch(ChildrenDrawer(false));
  };

  const PushInput = () => {
    setIndex(index + 1);
    let obj = {};
    obj = {
      id: index+1,
      name: `user ${index+ 1}`,
    };
    data.push(obj);
  };

  const RemoveInput = () => {
    if (data.length > 5) {
      let test = data.filter((e) => e.id !== data[data.length - 1].id);
      setData(test);
    } else {
      message.success({ content: "Loaded!", duration: 100 });
    }
  };

  const onClose = () => {
    dispatch(GroupMining(false));
  };

  const [form, setForm] = useState([
    {
      name: "",
      name1: "",
    },
  ]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setForm({ ...form, [name]: value });
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  return (
    <Drawer
      title="Mining Group"
      width={isMobile ? "70%" : "520px"}
      closable={false}
      onClose={onClose}
      open={groupMining}
    >
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        expandIconPosition={"end"}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header="HightLand"
          key="1"
          className="site-collapse-custom-panel"
        >
          {data?.map((e, i) => {
            return (
              <div key={i}>
                <Input
                  type="text"
                  value={e.name}
                  style={{ marginBottom: "10px" }}
                />
                <div style={{ marginBottom: "10px" }}>
                  status: <strong style={{ color: "green" }}>true</strong>
                </div>
              </div>
            );
          })}
          <Button
            type="primary"
            style={{ width: "89px", marginRight: "5px" }}
            onClick={showChildrenDrawer}
          >
            Add User
          </Button>
          <Button type="danger" style={{ width: "80px" }}>
            Mining
          </Button>
        </Panel>
        <Panel
          header="Phuc Long"
          key="2"
          className="site-collapse-custom-panel"
        >
          <p>{text}</p>
        </Panel>
        <Panel header="Coffe" key="3" className="site-collapse-custom-panel">
          <p>{text}</p>
        </Panel>
      </Collapse>

      <Button
        type="primary"
        onClick={showChildrenDrawer}
        style={{ marginTop: "10px" }}
      >
        Create Group
      </Button>

      <Drawer
        title="Add User"
        width={320}
        closable={false}
        onClose={onChildrenDrawerClose}
        open={childrenDrawer}
        extra={
          <Space>
            <Button onClick={onChildrenDrawerClose}>Cancel</Button>
            <Button onClick={onClose} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            {data?.map((e, i) => {
              return (
                <Col span={24} key={i}>
                  <Form.Item
                    key={i}
                    name="name"
                    label={e.name}
                    rules={[
                      {
                        required: true,
                        message: "Please enter user name",
                      },
                    ]}
                  >
                    <Input
                      value={form[e.name]}
                      name={e?.name}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                    <Input
                      value={form[e.name]}
                      name={e?.name}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              );
            })}

            <Col span={12} onClick={PushInput}>
              <Button>Add</Button>
            </Col>

            <Col span={12} onClick={RemoveInput}>
              <Button>Remote</Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Drawer>
  );
}
