import { Links } from "./data";
import {
  Content,
  DashboardWrapper,
  Drawers,
  LinkMain,
  OverLay,
} from "./styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import Light from "assets/img/light.png";
import Header from "components/GlobalHeaderDashboard";
import Popup from "components/Popup";
import SVG from "components/SVG";
import OverlayContent from "layouts/components/OverlayContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, Link, useNavigate, Navigate } from "react-router-dom";
import { HandleOpenDrawer, Overlay } from "redux/slices/auth";
import { dashboard } from "routes/Dashboard";
import MenuDesk from "./MenuDesk";
import { GetUser } from "utits/auth";

const Dashboard = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const user = GetUser();
  const { openDrawer } = useSelector((state) => state.auth);
  useEffect(() => {
    const title = dashboard.filter(
      (item) => item.path === location.pathname
    )?.[0];
    title
      ? (document.title = `${title.name} | Tobe NFT`)
      : (document.title = "Tobe NFT");
  }, [location]);

 


  if (!user) return <Navigate to='/' />

  return (
    <>
      <MenuDesk />
      <DashboardWrapper>

        <Content openDrawer={openDrawer}>
          <Header></Header>
          <Outlet />
          {/* {location.pathname !== "/earning" && <Footer />} */}
          <Popup />
        </Content>

        <OverlayContent />
        <OverLay
          onClick={() => dispatch(HandleOpenDrawer(false))}
          openDrawer={openDrawer}
        />
      </DashboardWrapper>
    </>
  );
};
export default Dashboard;
