import fb from "assets/svg/social/fb.svg";
import ins from "assets/svg/social/ins.svg";
import tele from "assets/svg/social/tele.svg";
import tw from "assets/svg/social/tw.svg";
import ytb from "assets/svg/social/ytb.svg";

export const Links = [
  {
    image: tele,
    link: "https://t.me/tobenft",
  },
  {
    image: tw,
    link: "https://twitter.com/tobenft?s=21&t=gat-TSebGygpiL2JP4EcWw",
  },

  {
    image: fb,
    link: "https://www.facebook.com/profile.php?id=100086592356812",
  },
];
