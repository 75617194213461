import fb from "assets/svg/social/fb.svg";
import ins from "assets/svg/social/ins.svg";
import tele from "assets/svg/social/tele.svg";
import tw from "assets/svg/social/tw.svg";
import ytb from "assets/svg/social/ytb.svg";

export const listHeader = [{
        title: "NFT",
        link: "/",
        children: [{
                name: "Location",
                link: "/maps",
            },
            {
                name: "Land Metaverse",
                link: "/land",
            },
            {
                name: "Picture",
                link: "/picture",
            },
        ],
    },


    {
        title: "Allocation",
    },
    // {
    //     title: "Tokenomics",
    // },
    {
        title: "Roadmap",
    },
    {
        title: "FAQ",
    },

];

export const ListMegaMenuChildren = [{
        title: "Location",
        link: "/maps",
    },
    {
        title: "Land Metaverse",
        link: "/land",
    },
    {
        title: "Picture",
        link: "/picture",
    },


];

export const Links = [{
        image: tele,
        link: "https://t.me/tobenft",
    },
    {
        image: tw,
        link: "https://twitter.com/tobenfts/",
    },

    {
        image: fb,
        link: "https://www.facebook.com/profile.php?id=100084102934272",
    },

];