/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { port } from "port";
import { getSession } from "utits/auth";

const API = axios.create({ baseURL: port });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.sessionID = `${getSession()} `;
  }
  return req;
});

export const req12Character = (data) =>
  API.post("api/characters/get12Characters", data);
export const req12CharacterTurn = (data) => {
  API.post("api/characters/turn12Characters", data);
};

export default {
  CreateApiS: (url, data, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${port}${url} `, {
      headers: {
        sessionID: `${session} `,
        "Content-Type": "multipart/form-data",
      },

      method: "POST",
      data,
    });
  },
  CreateApi1: (url, data, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${port}${url} `, {
      headers: {
        sessionID: `${session} `,
      },

      method: "POST",
      data,
    });
  },
  CreateApiSS: (url, data) => {
    return axios(`${port}${url} `, {
      method: "POST",
      data,
    });
  },

  CreateApiSSS: (url, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${port}${url} `, {
      headers: {
        sessionID: `${session} `,
      },
      method: "GET",
    });
  },

  CreateApiPOST: (url, session) => {
    if (session) {
      session = getSession();
    }
    return axios(`${port}${url} `, {
      headers: {
        sessionID: `${session} `,
      },
      method: "POST",
    });
  },
  CreateApiGET: (url) => {
    return axios(`${port}${url} `, {
      method: "GET",
    });
  },
};
