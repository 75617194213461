import { Item, NFTLocationStyles, Pagi } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Box, CircularProgress, Pagination } from "@mui/material";
import EmptyData from "components/EmptyData";
import {
  fixedNumber,
  formatMoney,
  formatMoneyDot,
} from "components/FormatNumber";
import { BuyNFTMarketplace } from "components/Message";
import _ from "lodash";
import { portImage } from "port";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleListMarketPlace } from "redux/slices/marketplace";
import { getColor, GetUser } from "utits/auth";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: "10px",
    },
    root: {
      "& .Mui-selected": {
        background: "#a100a5!important",
        color: "white",
      },
      "& ul > li > button": {
        backgroundColor: "white",
        color: "black",
      },
    },
  })
);
const NFTLocation = () => {
  const user = GetUser();
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listMarketPlace } = useSelector((state) => state.marketplace);
  const { loaddingSell } = useSelector((state) => state.nft);

  useEffect(() => {
    const data = {
      page: page,
      keywords: "",
      locationid: "",
      cateloryid: "",
    };
    dispatch(handleListMarketPlace(data));
  }, [page]);

  const handleBuyNFT = (locationid) => {
    if (user) {
      BuyNFTMarketplace(dispatch, locationid);
    } else {
      navigate("/auth/login");
    }
  };
  return (
    <>
      <NFTLocationStyles.Main>
        <NFTLocationStyles.Container className="container">


          {listMarketPlace?.total === 0 && <EmptyData />}
          {listMarketPlace?.data?.map((item, index) => (
            <Item.Main key={index}>
              <Item.Container>
                <Item.Head
                  style={{
                    background: `url(${portImage + item?.images[0]})`,
                  }}
                >
                  <Item.Price>
                    ${formatMoneyDot(String(fixedNumber(item?.price, 2)))}
                  </Item.Price>
                  <Item.Content>
                    <Item.Category>{item?.category}</Item.Category>
                    <Item.Name>{item?.name}</Item.Name>
                    <Item.Address>{item?.address}</Item.Address>
                    <Item.Ratting>
                      {_.times(5, (i) => (
                        <FontAwesomeIcon
                          icon="star"
                          style={{ color: getColor(item?.rating, i) }}
                        />
                      ))}
                    </Item.Ratting>
                  </Item.Content>
                  <Item.Overlay></Item.Overlay>
                </Item.Head>
              </Item.Container>

              <Item.Bottom>
                <Item.Detail
                  onClick={() => navigate(`/maps/${item?.locationid}`)}
                >
                  Detail
                </Item.Detail>
                <Item.Buy
                  onClick={() => {
                    handleBuyNFT(item?.locationid);
                  }}
                >
                  {loaddingSell === item?.locationid ? (
                    <CircularProgress
                      style={{
                        width: "15px",
                        height: "15px",
                        color: "white",
                        margin: "auto",
                      }}
                    />
                  ) : (
                    " Buy Now"
                  )}
                </Item.Buy>
              </Item.Bottom>
            </Item.Main>
          ))}


        </NFTLocationStyles.Container>
      </NFTLocationStyles.Main>
      {listMarketPlace?.total > 12 && (
        <Pagi>
          <Box className={classes.box}>
            <Pagination
              count={
                listMarketPlace?.total
                  ? Math.ceil(listMarketPlace?.total / 12)
                  : 0
              }
              size="large"
              onChange={(e, p) => setPage(p)}
              className={classes.root}
            />
          </Box>
        </Pagi>
      )}
    </>
  );
};
export default NFTLocation;
