import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Block = {
  Container: styled.div`
    overflow: scroll;
  `,
  Main: styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 1060px;
    overflow: scroll;
    padding: 90px 0;
    position: relative;

    > div .active {
      background: linear-gradient(
        114.6deg,
        rgba(0, 195, 152, 0.2) -13.48%,
        rgba(207, 251, 32, 0.2) 98.23%
      );
      border-radius: 20px;
      width: 159px;
      height: 129px;
      position: relative;
      >div{
        background: linear-gradient(115.36deg, #9CF75B 33.92%, #02FFAD 99.86%);
      }
     
    }
  `,
  Top: styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0 70px;
  `,
  Bottom: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 17px;
  `,

  Item: styled.div`
    width: 159px;
    height: 129px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(6, 100, 153, 0.3);
    box-shadow: 0px 10px 28px rgb(0 0 0 / 12%);
    position: relative;
  `,
  Number: styled.div`
    color: white;
    width: 52px;
    height: 52px;
    background: linear-gradient(115.36deg, #36b1ac 33.92%, #08021c 99.86%);
    border: 4px solid #ffffff;
    border-radius: 50%;
    ${themes.mixin.flexCenter()};
    position: absolute;
    top: -20px;
    right: 0;
  `,
  Price: styled.p`
    color: #02FFAC;
    font-size: 28px;
    font-weight: bold;
  `,
  Date: styled.p`
    color: white;
    font-size: 12px;
    font-weight: 400;
  `,
  Total: styled.p`
    color: white;
    font-size: 12px;
    font-weight: 400;
  `,
};

export const Line = {
  Main: styled.div`
    position: relative;
  `,
  Timeline: styled.div`
    position: absolute;
    top: 8px;
    width: 100%;
    height: 2px;
    background: aliceblue;
  `,
  Icon: styled.div`
    display: flex;
    justify-content: space-around;
  `,
};
