import { Overlay } from "../auth";
import { handleGetLocationDetail } from "../maps";
import { handleListMarketPlace } from "../marketplace";
import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";
import { toast } from "react-toastify";

const initialState = {
  listNft: false,
  loadding: false,
  listVirtualMachine: [],
  listMyNftCard: [],
  listMyVirtualMachine: [],
  listMyRealEstate: [],
  loaddingSell: false,
};

export const handleGetListNft = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS("public/card/list-card", true);
      console.log(res);
      dispatch(ListNft(res?.data?.data));
    } catch (error) {}
  };
};

export const handleGetMyNftCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/card/history-buy-card",
        true
      );
      dispatch(ListMyNftCard(res?.data?.data));
    } catch (error) {}
  };
};

export const handleGetListVirtualMachine = () => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/package/get-package",
        true
      );
      dispatch(ListVirtualMachine(res?.data?.data));
      dispatch(Loadding(false));
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};
export const handleGetMyVirtualMachine = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/package/history-package?limit=1000&page=1",
        true
      );
      dispatch(ListMyVirtualMachine(res?.data?.data));
    } catch (error) {}
  };
};
export const handleGetMyRealEstate = () => {
  return async (dispatch) => {
    dispatch(Loadding(true));

    try {
      let res = await CreateApi.CreateApiSSS("public/market/my-location", true);
      dispatch(ListMyRealEstate(res?.data?.data));
      dispatch(Loadding(false));
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleBuyNft = (id) => {
  const data = { cardid: id };
  return async (dispatch) => {
    dispatch(Loadding(id));
    try {
      let res = await CreateApi.CreateApi1(`public/card/buy-card`, data, true);
      if (res?.data?.status === true) {
        dispatch(Loadding(false));
        toast("Buy Nft Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res?.data?.message);
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
      console.log(error);
      if (error?.response?.status == 404) {
        return toast.error(`${error.message}`, {
          position: "top-right",
        });
      }
    }
  };
};

export const handleSellNFTLocation = (data) => {
  return async (dispatch) => {
    dispatch(LoaddingSell(data?.locationid));
    try {
      let res = await CreateApi.CreateApiS(
        `public/market/post-for-sale`,
        { ...data },
        true
      );
      if (res?.data?.status === true) {
        dispatch(handleGetMyRealEstate());
        dispatch(LoaddingSell(false));
        toast("Sell NFT Location Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res?.data?.message);
        dispatch(LoaddingSell(false));
      }
    } catch (error) {
      dispatch(LoaddingSell(false));
      console.log(error);
      if (error?.response?.status == 404) {
        return toast.error(`${error.message}`, {
          position: "top-right",
        });
      }
    }
  };
};

export const handleBuyNFTMarketplace = (data) => {
  return async (dispatch) => {
    dispatch(LoaddingSell(data?.locationid));
    try {
      let res = await CreateApi.CreateApiS(
        `public/market/buy-market-location`,
        data,
        true
      );
      if (res?.data?.status === true) {
        const data = {
          page: 1,
          keywords: "",
          locationid: "",
          cateloryid: "",
        };
        dispatch(handleListMarketPlace(data));
        dispatch(LoaddingSell(false));
        toast("Buy NFT Location Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res?.data?.message);
        dispatch(LoaddingSell(false));
      }
    } catch (error) {
      dispatch(LoaddingSell(false));
      console.log(error);
      if (error?.response?.status == 404) {
        return toast.error(`${error.message}`, {
          position: "top-right",
        });
      }
    }
  };
};

export const handleBuyNFTLocation = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/buy-location`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(Loadding(false));

        dispatch(handleGetLocationDetail(data));
        toast("Buy NFT Location Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res?.data?.message);
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
      console.log(error);
      if (error?.response?.status === 404) {
        return toast.error(`${error.message}`, {
          position: "top-right",
        });
      }
    }
  };
};
export const handleCancelSellNFTLocation = (data) => {
  return async (dispatch) => {
    dispatch(LoaddingSell(data));
    try {
      let res = await CreateApi.CreateApiS(
        `public/market/cancel-sell?locationid=${data}`,
        { ...data },
        true
      );
      if (res?.data?.status === true) {
        dispatch(handleGetMyRealEstate());
        dispatch(LoaddingSell(false));
        toast("Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res?.data?.message);
        dispatch(LoaddingSell(false));
      }
    } catch (error) {
      dispatch(LoaddingSell(false));
      console.log(error);
      if (error?.response?.status == 404) {
        return toast.error(`${error.message}`, {
          position: "top-right",
        });
      }
    }
  };
};

export const handleBuyNFTMachine = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/package/buy-package`,
        { ...data },
        true
      );
      if (res?.data?.status === true) {
        toast("Buy NFT Machine Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(Loadding(false));
        dispatch(Overlay(""));
      } else {
        toast.error(res?.data?.message);
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
      console.log(error);
      if (error?.response?.status == 404) {
        return toast.error(`${error.message}`, {
          position: "top-right",
        });
      }
    }
  };
};

const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    Loadding: (state, action) => {
      return {
        ...state,
        loadding: action.payload,
      };
    },
    LoaddingSell: (state, action) => {
      return {
        ...state,
        loaddingSell: action.payload,
      };
    },
    ListNft: (state, action) => {
      return {
        ...state,
        listNft: action.payload,
      };
    },
    ListVirtualMachine: (state, action) => {
      return {
        ...state,
        listVirtualMachine: action.payload,
      };
    },
    ListMyVirtualMachine: (state, action) => {
      return {
        ...state,
        listMyVirtualMachine: action.payload,
      };
    },
    ListMyNftCard: (state, action) => {
      return {
        ...state,
        listMyNftCard: action.payload,
      };
    },
    ListMyRealEstate: (state, action) => {
      return {
        ...state,
        listMyRealEstate: action.payload,
      };
    },
  },
});

const { reducer, actions } = nftSlice;
export const {
  ListNft,
  Loadding,
  LoaddingSell,

  ListVirtualMachine,
  ListMyNftCard,
  ListMyVirtualMachine,
  ListMyRealEstate,
} = actions;
export default reducer;
