import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import CreateApi from "api/CreateApi";
import {
  ErrorMessage,
  MessageToastError,
  SuccessMessage,
} from "components/Message";

const initialState = {
  groupMining: false,
  childrenDrawer: false,
  listTimeCard: [],
  checkMining: [],
  checkTimeMiningCard: false,
  FinishMiningCard: false,
  startMiningCard: false,
  finishCard: [],
  rewardEarningCard: [],
};

export const handleGetListTimeCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/minning/timming-card?coin=NOW",
        true
      );
      dispatch(ListTimeCard(res?.data?.data));
    } catch (error) {}
  };
};
export const handleGetRewardEarningCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/minning/config-minning-card",
        true
      );
      dispatch(RewardEarningCard(res?.data?.data));
    } catch (error) {}
  };
};

export const handleStartMinningCard = (
  time,
  type,
  id,
  type_range,
  setCheckedStateCard
) => {
  const data = {
    time: time,
    type: type,
    locationid: id,
    type_range: type_range,
  };
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/start-card`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(StartMiningCard(true));
        dispatch(handleCheckTimeMiningCard());
        setCheckedStateCard(true);
      } else {
        dispatch(StartMiningCard(false));
        setCheckedStateCard(false);
        MessageToastError(res?.data?.message);
      }
    } catch (error) {}
  };
};

export const handleFinishCard = (
  locationid,
  setCheckedStateCard,
  type_range
) => {
  const data = {
    locationid: 403,
    type_range: type_range,
  };
  return async (dispatch) => {
    const key = "updatable";
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/minning-card`,
        data,
        true
      );
      if (res?.data?.status === true) {
        message.loading({ content: "Loading...", key });
        setTimeout(() => {
          message.success({
            content: "Successful income!!",
            key,
            duration: 10,
          });
        }, 1000);
        dispatch(FinishMiningCard(true));
        dispatch(FinishCard(res?.data?.data));
        setCheckedStateCard(false);
      } else {
        setCheckedStateCard(true);
        MessageToastError(res?.data?.message);
      }
    } catch (error) {}
  };
};
export const handleCheckTimeMiningCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/time-minning-card`,
        true
      );
      if (res?.data?.status === false) dispatch(CheckTimeMiningCard(false));
      else {
        dispatch(CheckTimeMiningCard(res?.data?.data));
      }
    } catch (error) {}
  };
};

export const handleCheckMining = (
  myLatiude,
  myLongitude,
  locaLatiude,
  locaLongitude
) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/check-minning?myLatiude=${myLatiude}&myLongitude=${myLongitude}&locaLatiude=${locaLatiude}&locaLongitude=${locaLongitude}`,
        true
      );
      dispatch(CheckMining(res?.data));
    } catch (error) {}
  };
};

const miningSlice = createSlice({
  name: "earningcard",
  initialState,
  reducers: {
    GroupMining: (state, action) => {
      return {
        ...state,
        groupMining: action.payload,
      };
    },
    ListTimeCard: (state, action) => {
      return {
        ...state,
        listTimeCard: action.payload,
      };
    },
    CheckMining: (state, action) => {
      return {
        ...state,
        checkMining: action.payload,
      };
    },
    FinishMiningCard: (state, action) => {
      return {
        ...state,
        finishMiningCard: action.payload,
      };
    },
    ChildrenDrawer: (state, action) => {
      return {
        ...state,
        childrenDrawer: action.payload,
      };
    },
    CheckTimeMiningCard: (state, action) => {
      return {
        ...state,
        checkTimeMiningCard: action.payload,
      };
    },
    StartMiningCard: (state, action) => {
      return {
        ...state,
        startMiningCard: action.payload,
      };
    },
    FinishCard: (state, action) => {
      return {
        ...state,
        finishCard: action.payload,
      };
    },

    RewardEarningCard: (state, action) => {
      return {
        ...state,
        rewardEarningCard: action.payload,
      };
    },
  },
});

const { reducer, actions } = miningSlice;
export const {
  GroupMining,
  ChildrenDrawer,
  ListTimeCard,
  CheckMining,
  CheckTimeMiningCard,
  FinishMiningCard,
  StartMiningCard,
  FinishCard,
  RewardEarningCard,
} = actions;
export default reducer;
