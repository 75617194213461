import Card from "./Card";
import RealEstate from "./RealEstate";
import VirtualMachine from "./VirtualMachine";
import { Background, Banner, Banners, MenuLink, Title } from "./styles";
import banner from "assets/img/background/bg-banner.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { MenuDrawer } from "redux/slices/menu";
import SVG from "components/SVG";

const data = [
  {
    name: "Real Estate",
    title: "/my-nft",
    link: "my-nft",
  },
  {
    name: "VOUCHER",
    title: "VirtualMachine",
    link: "my-nft",
  },
  {
    name: "Tobe ID",
    title: "card",
    link: "my-nft",
  },
  {
    name: "Picture",
    title: "Picture",
    link: "my-nft",
  },
];


const MyNFT = () => {
  const dispatch = useDispatch()
  const { menuDrawer } = useSelector((state) => state.menu);
  return (
    <>
      <Banners>
        <Background>
          <img src={banner} alt="" style={{ width: "100%" }} />
          <MenuLink.main>
          <div className="container">
            <MenuLink.item>
              {data?.map((e, i) => (
                <MenuLink.active
                  ac={menuDrawer == e?.title}
                  onClick={() => dispatch(MenuDrawer(e.title))}
                >
                  {e.name}
                </MenuLink.active>
              ))}
            </MenuLink.item>

          
          </div>
        </MenuLink.main>
        <Banner.main>
          <div className="container">
            <Banner.item>
              <SVG name="vfc"></SVG>
              {menuDrawer === "card" && (
                <div>
                  <span>Tobe ID</span>
                  <p>
                    Earn your NFT items and trade them on the marketplace to
                    make money
                  </p>
                  {/* <span>Wallet {" "}{menuDrawer == "/wallet" ? '' :"=>"+ menuDrawer}</span> */}
                </div>
              )}
               {menuDrawer === "VirtualMachine" && (
                <div>
                  <span>Gift Voucher</span>
                  <p>
                  Earn your NFT items and trade them on the marketplace to make money
                  </p>
                  {/* <span>Wallet {" "}{menuDrawer == "/wallet" ? '' :"=>"+ menuDrawer}</span> */}
                </div>
              )}
               {menuDrawer === "/my-nft" && (
                <div>
                  <span>Real Estate NFTs</span>
                  <p>
                  (to be sold as Real Estate NFT with exact longitude and latitude)
                  </p>
                  {/* <span>Wallet {" "}{menuDrawer == "/wallet" ? '' :"=>"+ menuDrawer}</span> */}
                </div>
              )}
              {menuDrawer === "Picture" && (
                <div>
                  <span>Picture</span>
                  <p>
                  (to be sold as Real Estate NFT with exact longitude and latitude)
                  </p>
                  {/* <span>Wallet {" "}{menuDrawer == "/wallet" ? '' :"=>"+ menuDrawer}</span> */}
                </div>
              )}
            </Banner.item>
          </div>
        </Banner.main>
          {/* <Title data-aos="fade-up" data-aos-offset="30">
            {menuDrawer === "/my-nft" && <h2>My NFT Location</h2>}

            {menuDrawer === "VirtualMachine" && <h2>My NFT Virtual Machine</h2>}

            {menuDrawer === "card" && <h2>My NFT Card </h2>}

            <p>
              Earn your NFT items and trade them on the marketplace to make
              money
            </p>
          </Title> */}
        </Background>
      </Banners>
      {menuDrawer === "/my-nft" && <RealEstate />}
      {menuDrawer === "card" && <Card />}
      {menuDrawer === "VirtualMachine" && <VirtualMachine />}
      {menuDrawer === "Picture" && <VirtualMachine />}

    </>
  );
};
export default MyNFT;
