import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";

const initialState = {
  getLoading: false,
  listPackage: [],
  getHistory: [],
  getListingFund: [],
  getPromotion: [],
  getMaxSupply: [],
  blockNow: [],
};

export const handleListPackage = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS("public/ico/listpackage", true);
      dispatch(ListPackage(res?.data));
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};
export const handleGetBlockNow = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(
        "public/block_ico/get-price-nft",
        true
      );
      if (res?.data?.status === true) {
        dispatch(BlockNow(res?.data?.data));
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};
export const handleGetListingFund = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(
        "public/block_ico/get-listfloor",
        true
      );
      dispatch(GetListingFund(res?.data?.data[0]));
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleGetMaxSupply = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(
        "public/block_ico/get-max-supply",
        true
      );
      dispatch(GetMaxSupply(res?.data?.data));
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleGetPromotion = () => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(
        "public/promotion_bonus/promotion",
        true
      );

      dispatch(GetPromotion(res?.data?.data));
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleGetHistory = (page) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/ico/historybuyICO",
        {
          limit: 5,
          page: page,
        },
        true
      );
      dispatch(GetHistory(res?.data));
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

const walletSlice = createSlice({
  name: "ico",
  initialState,
  reducers: {
    GetLoading(state, action) {
      return {
        ...state,
        getLoading: action.payload,
      };
    },
    GetListingFund(state, action) {
      return {
        ...state,
        getListingFund: action.payload,
      };
    },
    GetMaxSupply(state, action) {
      return {
        ...state,
        getMaxSupply: action.payload,
      };
    },
    GetPromotion(state, action) {
      return {
        ...state,
        getPromotion: action.payload,
      };
    },
    ListPackage(state, action) {
      return {
        ...state,
        listPackage: action.payload,
      };
    },
    GetHistory(state, action) {
      return {
        ...state,
        getHistory: action.payload,
      };
    },
    BlockNow(state, acction) {
      return {
        ...state,
        blockNow: acction.payload,
      };
    },
  },
});

const { reducer, actions } = walletSlice;
export const {
  GetLoading,
  ListPackage,
  GetHistory,
  GetListingFund,
  GetPromotion,
  GetMaxSupply,
  BlockNow,
} = actions;
export default reducer;
