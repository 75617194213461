import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";

const initialState = {
    ListLandMetaverse:[]
};





export const handleGetListLand = (latiude,longitude,kilometers) => {

    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiSSS(`public/minning/list-metaverse?latiude=${latiude}&longitude=${longitude}&kilometers=${kilometers}&limit=all`, true);
            if (res?.data?.status == true) {
                // SuccessMessage('Exchange Success')
                dispatch(ListLandMetaverse(res?.data?.data))
             
            }
            else {
                // ErrorMessage(res?.data?.message)
            }

        } catch (error) {
            // ErrorMessage('Please check again')
        }
        dispatch(GetLoading(false))
    };

}





const landSlice = createSlice({
    name: "land",
    initialState,
    reducers: {
        GetLoading: (state, action) => {
            return {
                ...state,
                getLoading: action.payload,


            }
        },
        ListLandMetaverse: (state, action) => {
            return {
                ...state,
                listLandMetaverse: action.payload,


            }
        },
        
    },
});

const { reducer, actions } = landSlice;
export const { ListLandMetaverse,GetLoading} = actions;
export default reducer;
