import imgSuccess from "assets/img/success.png";
import SVG from "components/SVG";
import { toast } from "react-toastify";
import { handleCreateLocation } from "redux/slices/maps";
import {
  handleBuyNFTLocation,
  handleBuyNFTMarketplace,
  handleSellNFTLocation,
} from "redux/slices/nft";
import { handleVerifyWithdraw } from "redux/slices/wallet";
import styled from "styled-components";
import Swal from "sweetalert2";

const Svg = styled.div`
  display: flex;
  align-items: center;
  > img {
    width: 25px;
    margin-right: 5px;
  }
`;

export const MessageToastSuccess = (des) => {
  toast(
    <Svg>
      <img src={imgSuccess} />
      {des}
    </Svg>
  );
};

export const MessageToastError = (des) => {
  toast.error(
    <Svg>
      <SVG name="error" />
      {des}
    </Svg>
  );
};

export const SuccessMessage = (title) => {
  Swal.fire({
    title: title,
    icon: "success",
    timer: 1500,
  });
};

export const ErrorMessage = (title) => {
  Swal.fire({
    title: title,
    icon: "error",
    timer: 1500,
  });
};

export const Enable2FA = (title) => {
  Swal.fire({
    title: title,
    text: "Please enable 2FA to use this function!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Enable",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = "/profile";
    }
  });
};

// export const MiningMessage = (title) => {
//     Swal.fire({
//         title: title,
//         icon: 'success',
//         color: '#ffffff',
//         background: 'linear-gradient(to right,#067780,#02a878 )',

//         timer: 3000
//     })
// }
export const MiningMessage = (title) => {
  Swal.fire({
    title: title,
    icon: "success",
    color: "#ffffff",
    background: "linear-gradient(to right,#067780,#02a878 )",
    backdrop: `
            rgba(0, 0, 123, 0.4)
            url("/images/bg-mined.gif")
            
        `,
    timer: 5000,
  });
};
export const swalWithBootstrapButtons = (
  dispatch,
  Overlay,

  data,
  navigate
) => {
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  Swal.fire({
    title: "Are you sure?",
    text: "The position fee is $50",
    icon: "success",
    showCancelButton: true,
    confirmButtonText: "I agree!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      // Swal.fire("Successfully!", "", "success");
      dispatch(handleCreateLocation(data, navigate));
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      dispatch(Overlay(""));
      Swal.fire("Cancelled", "", "error");
    }
  });
};

export const SellNFTLocation = (dispatch, locationid) =>
  Swal.fire({
    title: "Enter the price you want to sell",
    input: "number",
    inputPlaceholder: "200 USDT",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Submit",
    showLoaderOnConfirm: true,
    preConfirm: (value) => {
      const data = {
        locationid: locationid,
        price: value,
        desc: "",
        title: "",
      };
      dispatch(handleSellNFTLocation(data));
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });

export const VerifyWithdraw = (dispatch) =>
  Swal.fire({
    title: "Enter the code sent via your email",
    input: "number",
    inputPlaceholder: "Code",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Submit",
    showLoaderOnConfirm: true,
    preConfirm: (value) => {
      const data = {
        code: value,
      };
      dispatch(handleVerifyWithdraw(data));
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });

export const BuyNFTMarketplace = (dispatch, locationid) => {
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "info",
    showCancelButton: true,
    confirmButtonText: "Yes, Buy now!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const data = {
        locationid: locationid,
      };
      dispatch(handleBuyNFTMarketplace(data));
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      Swal.fire("Cancelled", "", "error");
    }
  });
};

export const BuyNFTLocation = (dispatch, locationid) => {
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "info",
    showCancelButton: true,
    confirmButtonText: "Yes, Buy now!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const data = {
        locationid: locationid,
      };
      dispatch(handleBuyNFTLocation(data));
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      Swal.fire("Cancelled", "", "error");
    }
  });
};
