import bg from "assets/img/background/bg-filter.jpg";
import styled from "styled-components";

export const FilterStyles = {
  Main: styled.div`
    // background: url(${bg});
    padding: 20px 0;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 5;
    border-top-left-radius:20px;
    border-top-right-radius:20px;

  `,
  Wrapper: styled.div`
    background: url(${bg});
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.32;
    background-size: cover;
    z-index: 0;
  `,
  Input: styled.input`
    flex: 2;
    padding: 0px 20px;
    height: 38px;

    background: linear-gradient(114.6deg, rgba(0, 195, 152, 0.2) -13.48%, rgba(207, 251, 32, 0.2) 98.23%);

    border-radius: 30px;
    color: white;
    @media (max-width: 640px) {
      padding: 10px 10px;
    }
  `,
  Select: styled.div`
    flex: 2;
    .css-1s2u09g-control {
      background: linear-gradient(114.6deg, rgba(0, 195, 152, 0.2) -13.48%, rgba(207, 251, 32, 0.2) 98.23%);
      border-radius: 30px;
      color: white;
    }
    .css-1pahdxg-control {
      background: linear-gradient(114.6deg, rgba(0, 195, 152, 0.2) -13.48%, rgba(207, 251, 32, 0.2) 98.23%);
      border-radius: 30px;
      color: white;
    }
    .css-1pndypt-Input {
      > input {
        color: white !important;
      }
    }
    .css-qc6sy-singleValue {
      color: white;
    }
  `,
  BtnSearch: styled.button`
    flex: 1;
    padding: 3px;
    border-radius: 30px;
    background: linear-gradient(114.6deg, rgba(0, 195, 152, 0.2) -13.48%, rgba(207, 251, 32, 0.2) 98.23%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    gap: 5px;
    @media (max-width: 640px) {
      padding: 10px 10px;
    }
  `,
  Container: styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    gap: 10px;
    gap: 10px;
    justify-content: space-around;
    @media (max-width: 640px) {
      flex-direction: column;
    }
  `,
  Range: styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
  `,
  InputRange: styled.input`
    width: 50%;
  `,
  Km: styled.div`
    color: white;
  `,
};
