import { themes } from "common/styles/theme";
import { ContainerStyleds } from "components/styles/Layout.Styled";
import styled from "styled-components";

export const Section = styled.section`
  z-index: 1;
  padding: 50px 0;
  @media(max-width:600px){
    overflow:auto;
  }
  > img {
    position: absolute;
    z-index: -1;
    height: 100%;
    top:0;
    left:0;

    
  }
`;

export const ContainerStyled = styled(ContainerStyleds)`
  flex-direction: column;
  gap: 40px;
  z-index: 1;
  // background: rgba(255, 255, 255, 0.2);
  //   border: 1px solid rgba(255, 255, 255, 0.16);
  //   border-radius: 18px;
  padding: 20px 30px;

 
  > div {
    width: 100%;
    > h2 {
      color: white;
      font-size: 32px;
      font-weight: 700;
    }
    > p {
      color: white;
    }
  }
  @media (max-width: 991px) {
    gap: 20px;
  }
  @media (max-width: 600px) {
    padding: 15px;
    min-width:600px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  column-gap: 50px;
  row-gap: 50px;
`;

export const Item = styled.div`
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  max-width: 320px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const Image = styled.div`
  > img {
    witdh: 100%;
  }
`;

export const Text = styled.div`
  color: #536981;
  ${themes.mixin.flexCenter()};
  width: 100%;
  max-width: ${(props) => (props.w == true ? "420px" : "")};
  width: ${(props) => (props.w == true ? "100%" : "")};
  margin-top: ${(props) => (props.w == true ? "20px" : "")};
  padding: 0 20px;
  > div {
    width: 100%;
    max-width: 130px;
    text-align: start;
    color: white;
    padding: 10px;
  }
  > p {
    padding: 0;
    text-align: start;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 500px;
  }
`;
export const TextItem = styled.p`
  margin-bottom: 0;
  width: 100%;
  padding: ${(props) => (props.pd == true ? "5px 0" : "10px 0")};
  font-family: "IBM Plex Sans";
  font-weight: 700;
  color: #fcdae1;
  font-size: 20px;
`;
export const DesMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    font-size: 12px;
  }
`;
export const Des = styled.p`
  color: #536981;
  max-width: 300px;
`;

export const CountDown = {
  main: styled.div`
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    margin-top: -35px;
    ${themes.mixin.flexCenter()};
    width: 100%;
    padding: 0 3px;
  `,
  item: styled.div`
    ${themes.mixin.flexCenter()};
    flex-direction: column;
    padding: 3px;

    > h1 {
      margin: 0;
      color: #ffdee3;
      font-size: 15px;
    }
    > p {
      color: #ffdee3;
      margin: 0;
      font-size: 8px;
    }
    > span {
      color: #ffdee3;
      font-size: 14px;
      margin-top: -15px;
    }
    @media (max-width: 500px) {
      padding: 3px;
      > h1 {
        font-size: 16px;
      }
    }
  `,
};

export const StyledButton = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  gap: 20px;
  > button {
    width: 105px;
    height: 30px;
    background: linear-gradient(90deg, #780d69 0%, #ec0174 100%);
    border-radius: 16.5px;
    font-weight: 700;
  }
`;

export const ItemFlex = styled.div`
  ${themes.mixin.flexColCenter()};
  justify-content: space-around;
  gap: 20px;
  width: 100%;
`;

export const Overlay = styled.div`
position: absolute;
height: 44px;
width: 99%;
background: ${(props) => props.bg};
bottom: 23px;
opacity: 0.8;
z-index: -1;
}
`;

export const BgNft = styled.div`
position: absolute;
width: 100%;
opacity: 0.8;
z-index: -1;
>img{
  width:100%;
}
}
`;

export const Items = {
  Main: styled.div`
    color: white;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
    background: rgba(20, 14, 37, 0.3);
    max-width: 270px;
    // border: 2px solid;
    // border-image-slice: 1;
    // border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    border: solid 1px #02FFAC;
    border-radius: 18px;
    width: 260px;
    > img {
      height: 316px;
      width: 175px;
      margin: auto;
    }
  `,
  Name: styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    background: linear-gradient(
      90deg,
      #ffffff 6.25%,
      #fcdae1 58.98%,
      #d285ad 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  Price: styled.div`
    font-weight: bold;
  `,
  Date: styled.div`
    font-weight: 400;

    font-size: 13px;
    line-height: 16px;
    color: #ffffff91;
  `,
  Buy: styled.button`
    background: linear-gradient(90deg, #780d69 0%, #ec0174 100%);
    border-radius: 16.5px;
    padding: 3px 10px;
  `,
  Profit: styled.div`
    font-weight: 400;

    font-size: 13px;
    line-height: 16px;
    color: #ffffff91;
  `,
  Bottom: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .REFUND {
      color: red;
    }
    .EXPIRED {
      color: yellow;
    }
  `,
  Image: styled.image``,
  Status: styled.div`
    color: green;
    font-weight: bold;
    width: 100%;
    text-align: center;
  `,
  Location: styled.div`
    color: #bb44bb;
    font-weight: bold;
  `,
};
