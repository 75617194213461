import { Wrapper } from "./styles";
import Image from "assets/svg/logoFooter.svg";
import SVG from "components/SVG";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { MenuDrawer } from "redux/slices/menu";

const NotVerifyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Wrapper.section>
      <div className="container">
        <Wrapper.main>
          <Wrapper.title>
            <h2> Complete Information</h2>
            {/* <SVG name="vfc" /> */}
          </Wrapper.title>
          <Wrapper.content>
            <h3>
              Status:{" "}
              <strong style={{ color: "#7ddd7d" }}>
                {" "}
                Please verify your account!
              </strong>
            </h3>
            <button
              onClick={() => {
                dispatch(MenuDrawer("Complete Information"));
                navigate("/profile");
              }}
            >
              Verify now
            </button>
          </Wrapper.content>
        </Wrapper.main>
      </div>
    </Wrapper.section>
  );
};
export default NotVerifyAccount;
