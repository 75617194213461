import { auth } from "./Auth";
import { dashboard } from "./Dashboard";
import { maps } from "./Maps";
import LoaddingGif from "components/LoaddingGif";
import Auth from "layouts/Auth";
import Dashboard from "layouts/Dashboard";
import ComingSoon from "pages/ComingSoon";
import CreateLocation from "pages/CreateLocation";

import Marketplace from "pages/MarketPlace";
import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const _HomeMain = lazy(() => import("pages/HomeMain"));
const _CreateLand = lazy(() => import("pages/CreateLand"));
const _Land = lazy(() => import("pages/Land"));




const Router = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div>
            <LoaddingGif />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<_HomeMain />} />
          <Route path="/metashop" element={<Marketplace />} />
          <Route path="/land" element={<_Land />} />
          <Route path="/create-land" element={<_CreateLand />} />
          <Route path="/create-location" element={<CreateLocation />} />
          <Route path="/" element={<Auth />}>
            {maps.map((route, index) => (
              <Route
                key={index}
                index={index !== 0}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
          <Route path="/" element={<Dashboard />}>
            {dashboard.map((route, index) => (
              <Route
                key={index}
                index={index !== 0}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route path="/auth" element={<Auth />}>
            {auth.map((route, index) => (
              <Route
                key={index}
                index={index !== 0}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
