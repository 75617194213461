import { Headers, LinkMain } from "./styles";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SVG from "components/SVG";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { handleLogout, HandleOpenDrawer, Overlay } from "redux/slices/auth";

import { ClearUserSession, GetUser } from "utits/auth";
import { Links } from "./data";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openDrawer } = useSelector((state) => state.auth);
  const user = GetUser();
  const wrapperRef = useRef();
  const [transparent, setTransparent] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleUserScrolling);
    return () => {
      window.removeEventListener("scroll", handleUserScrolling);
    };
  }, [transparent]);

  const handleUserScrolling = () => {
    if (wrapperRef && wrapperRef.current) {
      const BOUNDARY_OF_TRANSPARANT_BG = wrapperRef.current.clientHeight;
      const scrollPosition = document.documentElement.scrollTop;
      if (scrollPosition > BOUNDARY_OF_TRANSPARANT_BG) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    }
  };

  const logout = () => {
    dispatch(handleLogout(navigate));
  };

  return (
    <div style={{background: "linear-gradient( 115.36deg,rgb(41 103 101 / 80%) 5%,rgba(8, 2, 28, 0) 99.86%)"}}>
      <Headers.Container bg={transparent ? transparent : ""} ref={wrapperRef}>
        <Link to="/">
          <SVG name="vfc"></SVG>
        </Link>
        {user ? (
          <>
           
            <div style={{position:'relative'}}>
          <LinkMain>
            {Links?.map((e, i) => (
              <a key={i} href={e.link} target="_blank">
                <img
                  key={i}
                  src={e?.image}
                  alt=""
                  style={{ width: "35px", height: "35px" }}
                />
              </a>
            ))}
          </LinkMain>
        </div>
         <Headers.IdAccount>
              <span style={{ textTransform: "uppercase" }}>
                {user?.username ? user?.username : user?.email?.substring(0, 9)}
                {/* {user?.username?.length > 8
                  ? user?.username.substring(0, 9) + "..."
                  : user?.username}{" "} */}
              </span>
            </Headers.IdAccount>
            <Headers.Logout onClick={logout}>
             <span> LOG OUT</span> <LogoutIcon />
            </Headers.Logout>
          </>
        ) : (
          <>
            <Headers.ButtonSecond onClick={() => dispatch(Overlay("login"))}>
              sign in
              <SVG name="signIn" />
            </Headers.ButtonSecond>
            <Headers.ButtonSecond>
              <NavLink to="/auth/register">sign up</NavLink>
            </Headers.ButtonSecond>
          </>
        )}
        {/* <Headers.ButtonMenu
          onClick={() => dispatch(HandleOpenDrawer(!openDrawer))}
        >
          <MenuIcon style={{ color: "white" }}></MenuIcon>
        </Headers.ButtonMenu> */}
      </Headers.Container>
    </div>
  );
}
export default Header;
