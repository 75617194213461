import {
  MessageToastError,
  MessageToastSuccess,
} from "../../../components/Message";
import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import axios from "axios";
import { port } from "port";
import { ClearUserSession, GetUser, setSession, SetUser } from "utits/auth";

const initialState = {
  openDrawer: false,
  overlay: false,
  getCountry: [],
  getLoading: false,
  // getUserStore: "" || GetUser(),
  getUserStore: "",

  getStatusKyc: "",
  value2fa: false,
  get2fa: "",
  getLevel: "",
};

export const HandleOpenDrawer = (payload) => {
  return async (dispatch) => {
    dispatch(OpenDrawer(payload));
  };
};

export const handleLogout = (navigate) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApi1("public/user/logout", {}, true);
      if (res?.data?.status === true) {
        ClearUserSession(dispatch);
        navigate("/");
        MessageToastSuccess("Logout successfully!");
      }
    } catch (error) {}
  };
};
export const HandleVerifyProfile = (data, setStep) => {
  return async (dispatch) => {
    dispatch(SetLoading(true));
    try {
      let res = await CreateApi.CreateApiS(
        "public/user/resend-verify-mail",
        {
          ...data,
        },
        true
      );

      if (res?.data?.status === true) {
        setStep(2);
      } else if (res?.data?.status === false) {
        dispatch(SetLoading(false));
        MessageToastError(res?.data?.message);
      }
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
};
export const HandleActiveCode = (data) => {
  return async (dispatch) => {
    dispatch(SetLoading(true));
    try {
      let res = await CreateApi.CreateApi1(
        "public/user/active-verify",
        {
          ...data,
        },
        true
      );

      if (res?.data?.status === true) {
        MessageToastSuccess("Successfully!");
        dispatch(handleGetProfile());
      } else if (res?.data?.status === false) {
        dispatch(SetLoading(false));
        MessageToastError(res?.data?.message);
      }
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
};
export const HandleSubmitSignUp = (data, navigate) => {
  return async (dispatch) => {
    dispatch(SetLoading(true));
    if (data.type == "left") {
      data.left = "left";
      data.right = "";
      delete data.type;
    } else {
      data.right = "right";
      data.left = "";
      delete data.type;
    }
    let res = await CreateApi.CreateApiSS(
      "public/account/signup",
      {
        ...data,
      },
      true
    );

    if (res?.data?.status == true) {
      MessageToastSuccess("Sign Up Success");
      setTimeout(() => {
        navigate(`/auth/await`);
      }, 1000);
    } else if (res?.data?.status == false)
      MessageToastError(res?.data?.message);
    dispatch(SetLoading(false));
  };
};
export const HandleSubmitSignUpGuest = (data, navigate) => {
  return async (dispatch) => {
    dispatch(SetLoading(true));
    let res = await CreateApi.CreateApiSS(
      "public/account/guest",
      {
        ...data,
      },
      true
    );

    if (res?.data?.status === true) {
      MessageToastSuccess("Sign Up Success");

      MessageToastSuccess("Logged in successfully");
      setSession(res?.data?.data.sessionID);

      let user = await CreateApi.CreateApiSSS("public/user/userinfo", true);
      SetUser(user?.data?.data, dispatch);

      setTimeout(() => {
        navigate("/account");
      }, 2000);
    } else if (res?.data?.status === false)
      MessageToastError(res?.data?.message);
    dispatch(SetLoading(false));
  };
};

export const HandleSubmitSignUpAuto = (data, navigate) => {
  return async (dispatch) => {
    dispatch(SetLoading(true));
    if (data.type == "left") {
      data.left = "left";
      data.right = "";
      delete data.type;
    } else {
      data.right = "right";
      data.left = "";
      delete data.type;
    }
    let res = await CreateApi.CreateApiSS(
      "public/account/signupAuto",
      {
        ...data,
      },
      true
    );

    if (res?.data?.status == true) {
      MessageToastSuccess("Sign Up Success");
      setTimeout(() => {
        navigate(`/auth/await`);
      }, 1000);
    } else if (res?.data?.status == false)
      MessageToastError(res?.data?.message);
    dispatch(SetLoading(false));
  };
};

export const handleVerifyEmail = (value, navigate) => {
  return async (dispatch) => {
    const data = new FormData();
    data.append("activation_code", value.get("code"));
    data.append("token", value.get("token"));

    const res = await axios({
      url: `${port}public/account/verify`,
      method: `POST`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
    if (res?.data?.status == true) {
      MessageToastSuccess("Success");
      setSession(res?.data?.data.sessionID);

      let user = await CreateApi.CreateApiSSS("public/user/userinfo", true);
      SetUser(user?.data?.data, dispatch);

      setTimeout(() => {
        navigate("/account");
      }, 2000);
    } else {
      MessageToastError(res.data.message);
    }

    // dispatch(SetLoading(false))
  };
};

export const handleGetCountry = () => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiSSS("public/country/list", true);
    dispatch(GetCountry(res?.data?.data));
  };
};

export const handleGetProfile = () => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiSSS("public/user/userinfo", true);
    dispatch(SetUser(res?.data?.data, dispatch));
  };
};

export const handleGetStatusKyc = () => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiPOST("public/user_kyc/info", true);
    dispatch(GetStatusKyc(res?.data));
  };
};
export const handleVerifyForgotPassword = (data) => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiSS(
      "public/account/password/forgot/generate",
      data,
      true
    );
  };
};

export const handleGet2FA = () => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiSSS("public/mfa/code", true);
    dispatch(Get2FA(res?.data?.data));
  };
};

export const handleGetLevel = () => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiSSS("public/level/user", true);

    dispatch(GetLevel(res?.data));
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    OpenDrawer: (state, action) => {
      return {
        ...state,
        openDrawer: action.payload,
      };
    },

    Overlay: (state, action) => {
      return {
        ...state,
        overlay: action.payload,
      };
    },

    SetLoading: (state, action) => {
      return {
        ...state,
        getLoading: action.payload,
      };
    },
    GetCountry: (state, action) => {
      return {
        ...state,
        getCountry: action.payload,
      };
    },

    GetUserStore: (state, action) => {
      return {
        ...state,
        getUserStore: action.payload,
      };
    },
    GetStatusKyc: (state, action) => {
      return {
        ...state,
        getStatusKyc: action.payload,
      };
    },
    Get2FA: (state, action) => {
      return {
        ...state,
        get2fa: action.payload,
      };
    },
    GetValue2fa: (state, action) => {
      return {
        ...state,
        value2fa: action.payload,
      };
    },
    GetLevel: (state, action) => {
      return {
        ...state,
        getLevel: action.payload,
      };
    },
  },
});

const { reducer, actions } = authSlice;
export const {
  OpenDrawer,
  Overlay,
  SetLoading,
  GetCountry,
  GetUserStore,
  GetStatusKyc,
  Get2FA,
  GetValue2fa,
  GetLevel,
} = actions;
export default reducer;
