import { FilterStyles } from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import { ErrorMessage } from "components/Message";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  handleGetListCategory,
  handleGetListCountry,
  handleGetListProvince,
  handleSearchLocation,
} from "redux/slices/maps";
import { handleListMarketPlace } from "redux/slices/marketplace";

const Filter = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState();
  const [category, setCategory] = useState();
  const [range, setRange] = useState(50);

  const [country, setCountry] = useState();
  const [province, setProvince] = useState();
  const [selectedProvince, setSelectedProvince] = useState([]);

  const { listCategory, listCountry, listProvince } = useSelector(
    (state) => state.maps
  );

  const optionsCate =
    listCategory &&
    listCategory?.map((item) => {
      return { value: item.id, label: item.name };
    });

  const optionsCountry =
    listCountry &&
    listCountry?.map((item) => {
      return { value: item.iso2, label: item.name, id: item.id };
    });

  const optionsProvince =
    listProvince &&
    listProvince?.map((item) => {
      return { value: item.iso2, label: item.name, id: item.id };
    });

  useEffect(() => {
    dispatch(handleGetListProvince(country));
    setSelectedProvince([]);
  }, [country]);

  useEffect(() => {
    dispatch(handleGetListCategory());
    dispatch(handleGetListCountry());
  }, []);

  const handleSearch = () => {
    if (category?.value || province?.id || country?.id || keyword) {
      let payload = {
        cateloryid: category?.value || "",
        locationid: province?.id ? province?.id : country?.id || "",
        keywords: keyword || "",
        page: 1,
      };
      dispatch(handleListMarketPlace(payload));
    } else {
      ErrorMessage("Please input data!");
    }
  };
  return (
    <FilterStyles.Main>
      {/* <FilterStyles.Wrapper></FilterStyles.Wrapper> */}
      <FilterStyles.Container className="container">
        {/* <FilterStyles.Range>
          <FilterStyles.InputRange
            type="range"
            min="1"
            max="100"
            defaultValue={50}
            onChange={(e) => setRange(e.target.value)}
          />
          <FilterStyles.Km>{range} Km</FilterStyles.Km>
        </FilterStyles.Range> */}
      </FilterStyles.Container>
      <FilterStyles.Container className="container">
        <FilterStyles.Input
          placeholder="Enter keyword"
          type="text"
          onChange={(e) => setKeyword(e.target.value)}
        />
        <FilterStyles.Select>
          <Select
            options={optionsCountry}
            placeholder="Country"
            onChange={(value) => setCountry(value)}
            isClearable={true}
          />
        </FilterStyles.Select>
        <FilterStyles.Select>
          <Select
            value={selectedProvince}
            options={optionsProvince}
            placeholder="Province"
            onChange={(value) => {
              setProvince(value);
              setSelectedProvince(value);
            }}
            isClearable={true}
          />
        </FilterStyles.Select>
        {/* <FilterStyles.Select>
          <Select
            options={optionsCate}
            placeholder="Category"
            onChange={(value) => setCategory(value)}
            isClearable={true}
          />
        </FilterStyles.Select> */}

        <FilterStyles.BtnSearch onClick={handleSearch}>
          <SearchIcon sx={{ color: "white" }} /> <span> Search</span>
        </FilterStyles.BtnSearch>
      </FilterStyles.Container>
    </FilterStyles.Main>
  );
};

export default Filter;
